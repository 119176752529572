<template>
  <div>
    <UpdateUploadDocumentEntry />
  </div>

</template>

<script>
import UpdateUploadDocumentEntry from '@/components/ui/landing/signup/UpdateUploadDocumentEntry.vue'
export default {
  components: {
    UpdateUploadDocumentEntry
  }
}
</script>