<template>
  <div>
    <Toolbar />
    <DealersListing />
    <FooterPane />
  </div>
</template>

<script>

import Toolbar from '@/nfmp_components/domain_components/Toolbar.vue'
import DealersListing from '@/nfmp_components/DealersListing.vue'
import FooterPane from '@/nfmp_components/domain_components/Footer.vue'

export default {
  components: {
    Toolbar,
    DealersListing,
    FooterPane

  }
}
</script>