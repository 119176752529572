<template>
  <div>
    <Toolbar />
    <Products />
    <FooterPane />
  </div>
</template>

<script>

import Toolbar from '@/nfmp_components/domain_components/Toolbar.vue'
import Products from '@/nfmp_components/Products.vue'
import FooterPane from '@/nfmp_components/domain_components/Footer.vue'

export default {
  components: {
    Toolbar,
    Products,
    FooterPane
  }
}
</script>
