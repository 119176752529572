<template>
  <v-sheet>
    <v-container class="py-5">
      <v-row>
        <v-col cols="12" md="7">
          <h5 class="text-h5 primary--text">Send a Message</h5>
          <v-form
            ref="form"
            v-model="valid"
            class="mt-2"
          >
            <v-text-field
              v-model="name"
              :counter="30"
              :rules="nameRules"
              label="Name" 
              hint="It must not be less than 30"
              required
            ></v-text-field>

            <v-text-field  
              v-model="email"
              :rules="emailRules"
              required 
              label="Email"
            >
            </v-text-field>

            <v-select
              :items="subject"
              :rules="[v => !!v || 'Item is required']"
              label="Subject *"
              required
            ></v-select>

            <v-textarea label="Message"></v-textarea>
            <v-btn
              submit
              medium
              color="primary rounded-0"
              @click="validate"
            >Send</v-btn>
          </v-form>
        </v-col>
        <v-col cols="12" md="4">
          <h5 class="text-h5 primary--text">Meet Us</h5>
          <v-divider></v-divider>
          <div class="font-weight-bold text-body-1">
            <div class="d-flex align-center mt-3">
              <v-icon>mdi-map-marker-outline</v-icon>
              <span class="ml-1">Bank of Agriculture (BoA) Plaza, 5th Floor Plot 162 Independence Avenue, Central Business District, Abuja</span>
            </div>
            <div class="d-flex align-center mt-3">
              <v-icon>mdi-phone-outline</v-icon>
              <span class="ml-1">
                <span><a href="#" class="text-decoration-none">+234-90 3382 9805 </a></span>
                <span><a href="#" class="text-decoration-none">+234-70 4823 7573 </a></span>
              </span>
            </div>
            <div class="d-flex align-center mt-3">
              <v-icon>mdi-email-outline</v-icon>
              <span class="ml-1">
                <a href="#" class="text-decoration-none">customercare@nfmp.gov.ng</a>
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    name: '',
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v.length <= 30) || 'Name must be less than 30 characters'
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    phoneNumber: '',
    phoneRules: [
      (v) => !!v || 'Phone Number is required',
      (v) => (v && v.length <= 11) || 'Name must be less than 11 characters'
    ],
    select: null,
    subject: [
      'Registration',
      'Payment'
    ]
    // checkbox: false
  }),

  methods: {
    validate () {
      this.$refs.form.validate()
    }
  }
}
</script>

<style>

</style>