<template>
  <div>
    <Toolbar />
    <Guideline />
    <FooterPane />

  </div>
</template>

<script>
import Toolbar from '@/nfmp_components/domain_components/Toolbar.vue'
import Guideline from '@/nfmp_components/Guideline.vue'
import FooterPane from '@/nfmp_components/domain_components/Footer.vue'

export default {
  components: {
    Toolbar,
    Guideline,
    FooterPane

  }
}
</script>