<template>
  <v-container class="">
    <div class="about p-2">
      <h5>Dealers Listing</h5>
    </div>
    <v-row class="green lighten-3 rounded-3 my-1 pa-0">
      <v-col cols="12" sm="8" md="4" class="py-1">
        <v-text-field
          v-model="name_search"
          label="Name"
          hint="Type Name of Company"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3" class="py-1">
        <v-select
          v-model="stateItemsId"
          return-object
          item-text="title"
          item-value="id"
          :items="stateItems"
          label="Select State *"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3" class="py-1">
        <v-select
          v-model="lineOfBusinessId"
          return-object
          item-text="title"
          item-value="id"
          :items="lineOfBusinessItems"
          label="Select Line Of Business *"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2" class="py-1">
        <v-btn class="mt-2 rounded-0 primary" @click="makeSearch">
          Search
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="isNotLoaded" class="mt-4">
      <v-row>
        <v-col cols="12" sm="6" md="6" class="p-0">
          <v-skeleton-loader
            class="mx-auto"
            max-width="550"
            type="list-item-avatar, divider, list-item-three-line, card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="6" md="6" class="p-0">
          <v-skeleton-loader
            class="mx-auto"
            max-width="550"
            type="list-item-avatar, divider, list-item-three-line, card-heading"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div v-if="searchResultStatus" class="p-3">
        <h3>
          {{ searchResultMessage }}
        </h3>
      </div>
      <div v-if="!searchResultStatus" class="mt-5">
        <v-row>
          <v-col
            v-for="(item, index) in dealersListitems"
            :key="index"
            cols="12"
            md="6"
          >
            <template>
              <v-card outlined flat class="h-full d-flex flex-column">
                <v-card-title class="font-weight-bold mb-0">
                  <div class="flex-grow-1 text-truncate text-capitalize">
                    {{ item.company_name }}
                    <v-divider class="ma-2"></v-divider>
                  </div>
                </v-card-title>
                <v-card-text class="flex-grow-1 p">
                  <div class="text-right">
                    <v-chip class="mr-1 white--text green">
                      {{ item.state }}
                    </v-chip>
                    <v-chip class="mr-1 white--text green">
                      {{ item.lob }}
                    </v-chip>
                  </div>
                  <div class="font-weight-normal text-capitalize">
                    {{ item.address }}
                  </div>
                  <!--<v-divider></v-divider>-->
                  <div class="d-flex justify-space-between">
                    <div class="d-flex my-1">
                      <v-icon
                        color="success"
                        class="mr-2"
                      >mdi-calendar-arrow-right</v-icon>
                      <span
                        class="caption font-weight-bold"
                      >Certificate Exp:&nbsp;</span>
                      <span class="caption">
                        {{ item.certificate_expiration_date }}</span>
                    </div>
                    <div class="d-flex my-1">
                      <v-icon
                        color="success"
                        class="mr-2"
                      >mdi-certificate-outline</v-icon>
                      <span
                        class="caption font-weight-bold"
                      >Certificate No:&nbsp;</span>
                      <span class="caption">
                        {{ item.certificate_number }}</span>
                    </div>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div class="d-flex my-1">
                      <v-icon color="success" class="mr-2">mdi-email</v-icon>
                      <span class="caption">{{ item.business_email }}</span>
                    </div>
                    <div class="d-flex my-1">
                      <v-icon color="success" class="mr-2">mdi-phone</v-icon>
                      <span class="caption">{{ item.business_phone }}</span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </template></v-col></v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import serv from '../services/HTTPService'

export default {
  data: () => ({
    isNotLoaded: true,
    selected: [2],
    dealersListitems: [],
    stateItems: [],
    stateItemsId: 0,
    lineOfBusinessItems: [],
    lineOfBusinessId: 0,
    name_search: '',
    searchResultStatus: false,
    searchResultMessage: ''
  }),
  computed: {
    searchResult() {
      return this.searchResultStatus
    }
  },
  created() {},
  mounted() {
    //const res_value = myServices.getDealers()
    //const res_value = serv.getDealers()
    const dealerUrl = serv.getGuestUrl('/dealers/')
    const resValue = serv.makeRequest(dealerUrl, 'GET')

    resValue.then((result) => {
      //console.log('our result is ', result)
      if (result.status === 200) {
        this.dealersListitems = result.data.dealers
      } else {
        this.snackbartext = result.message
        this.snackbar = true
      }
    })
    const dealerSearchUrl = serv.getGuestUrl('/formOptions/dealers/search')
    const resValue2 = serv.makeRequest(dealerSearchUrl, 'GET')

    resValue2.then((result) => {
      if (result.status === 200) {
        this.stateItems = result.data.state
        this.lineOfBusinessItems = result.data.LOB
      } else {
        //alert(result.message)
        this.snackbartext = result.message
        this.snackbar = true
      }
      this.isNotLoaded = false
    })
  },
  methods: {
    makeSearch: function () {
      const sendData = {}

      //if (this.name_search !== undefined) sendData.name = this.name_search
      let searchCount = 0

      if (this.name_search !== '') {
        sendData.name = this.name_search
        searchCount++
      }
      if (
        this.lineOfBusinessId.title !== undefined &&
        this.lineOfBusinessId.title !== null
      ) {
        sendData.LOB = this.lineOfBusinessId.title
        searchCount++
      }
      if (
        this.stateItemsId.title !== undefined &&
        this.stateItemsId.title !== null
      ) {
        sendData.state = this.stateItemsId.title
        searchCount++
      }
      if (searchCount === 0) {
        this.snackbartext = 'No serch data!'
        this.snackbar = true

        return
      }

      this.isNotLoaded = true
      const dealerUrl = serv.getGuestUrl('/dealers/search')
      const resValue = serv.makeRequest(dealerUrl, 'GET', sendData)

      resValue.then((result) => {
        //this.searchResultStatus = true
        this.isNotLoaded = false
        if (result.status === 200) {
          this.dealersListitems = result.data.dealers
        } else {
          this.snackbartext = result.message
          this.snackbar = true
        }
        this.isNotLoaded = false
      })
    }
  }
}
</script>

<style>
.about {
  border-left: 4px solid #4caf50;
}

.t-yellow {
  color: #4caf50 !important;
}
.bolden {
  font-weight: bold !important;
}
</style>