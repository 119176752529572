<template>
  <v-container>
    <div class="about p-2">
      <h3>Integrated Farmers Platform</h3>
    </div>
    <v-row class="pa-0 ma-1">
      <v-card color="basil">
        <v-tabs 
          fixed-tabs
          show-arrows
          background-color="green lighten-1"
          slider-color="yellow"
          dark
        >
          <v-tab>Corperatives Registration</v-tab>
          <v-tab>Farmers Registration</v-tab>
          <v-tab>Login</v-tab>
          <v-tab-item>
            <Corperatives />
          </v-tab-item>
          <v-tab-item>
            <Farmers />
          </v-tab-item>
          <v-tab-item>
            <SignIn />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import Corperatives from '@/nfmp_components/farmers/CorperativesReg.vue'
import Farmers from '@/nfmp_components/farmers/FarmersReg.vue'
import SignIn from '@/nfmp_components/farmers/SignIn.vue'

export default {
  components: {
    Corperatives,
    Farmers,
    SignIn
  }
}
</script>
  <style scoped>
.about {
  border-left: 4px solid #4caf50;
}
.welcome {
  padding-top: 15px;
  font-weight: 200 !important;
}
.abt-text {
  font-size: 35px !important;
}
</style>