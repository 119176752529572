<template>
  <v-container>
    <div class="branch p-3">
      <h3>Our Branches
      </h3>
    </div>
    <v-row 
      color="transparent" 
      class="px-1 py-5" 
    >
      <v-card
        class="mx-auto"
        tile
      >
        <v-list dense class="ml-0">
          <v-list-item-group>
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              class="border-bottom py-0"
            >
              <v-list-item-icon class="mb-0">
                <v-avatar class="primary" size="50">
                  <v-icon color="white" v-text="item.icon"></v-icon>
                </v-avatar>
              </v-list-item-icon>
              <v-list-item-content class="ml-3">
                <div class="text-h6 text-gray"> {{ item.title }}</div>
                <v-list-item-title class="mb-0" v-text="item.text"></v-list-item-title>
                <label>{{ item.address }}</label>
              </v-list-item-content>
              <p color="gray">{{ item.phone_num }}</p>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    selectedItem: 1,
    items: [
      { text: 'FCT', icon: 'mdi-crosshairs-gps', title: 'Abuja', address: 'Address No. 38 Tunis Street Wuse, Zone 5, Abuja', phone_num: '080 xxxx xxxx' },
      { text: 'Lagos', icon: 'mdi-crosshairs-gps', title: 'Lagos', address: 'Address No. 38 Tunis Street Wuse, Zone 5, Abuja', phone_num: '080 xxxx xxxx' },
      { text: 'Conversions', icon: 'mdi-crosshairs-gps', title: 'Kano', address: 'Address No. 38 Tunis Street Wuse, Zone 5, Abuja', phone_num: '080 xxxx xxxx' }
    ]
  })
}
</script>

<style>
v-icon{
  font-size: 14px !important;
}
.branch{
  border-left: 4px solid #4CAF50;
}
</style>