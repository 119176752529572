<template>
  <div>
    <v-container class="">
      <v-row class="">
        <v-col
          cols="12"
          md="4"
        >
          <v-card outlined class="rounded-0">
            <v-card-title class="font-weight-bold secondary card-height">
              <v-icon class="primary--text icon-size">mdi-alert-octagon</v-icon>
              <h6 class=" card-height">
                Whistle blowing
              </h6>
            </v-card-title>
            
            <v-card-actions class=" card-height">
              <v-btn class="" to="whistleblowing">
                See more
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card outlined class="rounded-0">
            <v-card-title class="font-weight-bold secondary card-height">
              <v-icon class="primary--text icon-size">mdi-email</v-icon>
              <h6 class=" card-height">
                Contact us
              </h6>
            </v-card-title>
            
            <v-card-actions class=" card-height">
              <v-btn class="" to="contactus">
                See more
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-card outlined class="rounded-0">
            <v-card-title class="font-weight-bold secondary card-height">
              <v-icon class="primary--text icon-size">mdi-chat-question</v-icon>
              <h6 class=" card-height">
                FAQ
              </h6>
            </v-card-title>
            
            <v-card-actions class=" card-height">
              <v-btn class="" to="faq">
                See more
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

    </v-container>
  </div>

</template>

<script>
export default {
  data() {
    return {
      help:[{
        icon: 'mdi-alert-octagon',
        title: 'Whistle blowing',
        label: 'See more',
        to: 'WhistleBlowing'
      },{
        icon: 'mdi-email',
        title: 'Contact Us',
        label: 'See more',
        to: 'ContactUs'
      },{
        icon: 'mdi-chat-question',
        title: 'FAQ',
        label: 'See more',
        to: 'faq'
      }
      ]
    }
  }
}
</script>

<style scoped>
.guide-heading{
  height: 80px;
  border-left: 4px solid #4CAF50;
  padding-left: 20px;
}

.shadow-card{
  box-shadow: 1px 2px 3px 2px rgb(179, 179, 179) !important;
}
.icon-size{
    font-size: 40px !important;
}
</style>