<template>
  <v-container class="py-6">
    <div class="top-f d-flex">
      <!-- <v-col
        cols="12"
        sm="2"
      > -->
      <div class="top-n">
        <img
          src="/images/logo.png"
          alt=""
          height="28"
          class="mt-2"
          width="120%"
          @click="$router.push('/')"
        >
      </div>
      <!-- </v-col>
      <v-col
        cols="12"
        sm="2"
        class=""
      > -->
      <div class="text-center ml-2 pt-2 top-n" @click="$router.push('/')">
        <h2 class="text-h3">FMARD </h2>
      </div>
      <div class=" pb-5 top-n">
        <h2 class="text-h4 na-feti" @click="$router.push('/')">National Fertilizer <br> Management Platform </h2>
      </div>
        
      <!-- </v-col>
      <v-col
        cols="12"
        sm="8"
      >
      tfgty
      </v-col> -->
    </div>
    <!-- <div style="width: 80px; height: 4px" class="mb-3 secondary mx-auto" /> -->

    <v-card class="pa-3 mx-auto" max-width="500">
      <v-card-title>
        <v-divider class="my-1"></v-divider>
        <div class="mx-2">Sign In</div>
        <v-divider class="my-1"></v-divider>
      </v-card-title>
      <v-card-text>
        <v-text-field 
          v-model="pword"
          label="Password"
          :type="showIcon ? 'text' : 'password'"
          outlined
          :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showIcon = !showIcon"
        ></v-text-field>
        <v-text-field label="Password" outlined hide-details></v-text-field>
        <v-checkbox dense label="Remember me"></v-checkbox>
        <v-btn block class="success-btn rounded-0" x-large @click="createPassword">Create Your Password</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    showIcon: fase
  }),
  methods: {
    submitSignin: () => {
      router.push({ name: 'userfeed' })
    }
  }
}
</script>

<style scoped>
.signin{
  padding: 20px;
  background-color: #329332;
  margin-bottom: 20px;
}
.here{
  color: #329332 !important;
  text-decoration: none !important;
}
.signinText{
  text-align: center;
}
.btn-sub{
  border: 2px solid #329332 !important;
  background-color: white !important;
  width: 120px;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  border-radius: 0px !important;
  color: #329332;
  margin-left: 70% !important;
}
.btn-sub:hover{
  background-color: #329332 !important;
  color: white !important;
}
.top-f{
justify-content: center;
color: #4CAF50;
}
.top-n:hover{
  cursor: pointer !important;
}
.na-feti{
  border-left: 4px solid#B3B303 !important;
}
.success-btn{
  background-color: #4CAF50 !important;
  color: white;
}
</style>