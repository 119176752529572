<!-- eslint-disable comma-dangle -->
<template>
  <div class="container grey lighten-5">
    <!-- <v-row> -->
    <v-stepper v-model="e13" vertical>
      <div class="createAcct"><h1>Upload Documents</h1></div>
      <v-stepper-step step="1" :complete="e13 > 1">
        <h4>CAC Document Upload</h4>
        <small>This document is mandatory</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card class="mb-12 white">
          <v-form ref="uploadcacform" fast-fail>
            <v-card class="mx-auto rounded-0" outlined>
              <div class="uploads secondary p-3">
                <h6 class="text-h6">CAC Certificate Upload</h6>
                <p>Upload a scan copy of the company C.A.C Certificate</p>
              </div>
            </v-card>
            <v-file-input
              v-model="uploadercac"
              type="file"
              class="grey lighten-5"
              show-size
              counter
              label="Upload your CAC certificates"
              :rules="imagerules"
              @change="onCACFileChanged"
            ></v-file-input>
            <div class="img_preview cac_preview">
              <v-img class="img-upload" :src="ourCACpic"></v-img>
            </div>
          </v-form>
        </v-card>
        <v-btn color="primary rounded-0" @click="UploadCAC()">Upload</v-btn>
        <!-- <v-btn text @click="e13 = 2" >Back to Step 2</v-btn> -->
      </v-stepper-content>

      <v-stepper-step step="2" :complete="e13 > 2">
        <h4>Government Issued ID Upload</h4>
        <small>The uploads is mandatory</small>
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-form ref="sendyouridform" v-model="valid" fast-fail>
          <v-card class="mx-auto rounded-0" outlined>
            <div class="uploads secondary p-3">
              <h6 class="text-h6">Government Issued ID</h6>
              <p>Upload a copy of your Government issued ID</p>
            </div>
          </v-card>
          <v-file-input
            v-model="uploadergovid"
            type="file"
            class="grey lighten-5"
            show-size
            counter
            label="Upload your Government Issued ID"
            :rules="imagerules"
            @change="onIDFileChanged"
          ></v-file-input>
          <div class="img_preview cac_preview">
            <v-img class="img-upload" :src="ourIDpic"></v-img>
          </div>
        </v-form>
        <v-btn text @click="backtoFirstStep()">Back to Step 1</v-btn> &nbsp;
        <v-btn
          color="primary rounded-0"
          @click="SendYourID"
        >Upload Government Issued ID</v-btn>
      </v-stepper-content>

      <v-stepper-step step="3" :complete="e13 > 3">
        <h4>Passport Photograph Upload</h4>
        <small>This document is mandatory</small>
      </v-stepper-step>
      <v-stepper-content step="3">
        <v-form ref="uploadpassportform" fast-fail>
          <v-card class="mx-auto rounded-0 mt-3" outlined>
            <div class="uploads secondary p-3">
              <h6 class="text-h6">Passport Size Photograph</h6>
              <p>Upload a scan copy of your Passport Size Photo</p>
            </div>
          </v-card>
          <v-file-input
            v-model="uploaderpassport"
            type="file"
            class="grey lighten-5"
            show-size
            counter
            label="Upload Passport Size Photo"
            :rules="imagerules"
            @change="onPassportFileChanged"
          ></v-file-input>
          <div class="img_preview passport_preview mb-1">
            <v-img class="img-upload" :src="ourPassportpic"></v-img>
          </div>
        </v-form>
        <v-btn text @click="backtoSecondStep()">Back to Step 2</v-btn> &nbsp;
        &nbsp;
        <v-btn
          color="primary rounded-0"
          @click="UploadPassport()"
        >Upload Passport Size Photo</v-btn>
      </v-stepper-content>

      <v-stepper-step step="4" :complete="e13 > 4">
        <h4>Uploads Review & Synchronization</h4>
        <small>Vet and synchronize uploads</small>
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-form ref="uploadgovidform" fast-fail>
          <v-card class="mx-auto rounded-0 mt-3 mb-1" outlined>
            <div class="uploads p-3">
              <h6 class="text-h6">
                Please confirm that the documents uploaded are correct?
              </h6>
              <p>
                If so, complete this process by click the button below to
                finalize the upload and complete your registration
              </p>
            </div>
          </v-card>
        </v-form>
        <v-btn text @click="backtoThirdStep()">Back to Step 3</v-btn> &nbsp;
        &nbsp;
        <v-btn
          color="primary rounded-0"
          :disabled="submitRegisterBtnStatus"
          @click="SubmitRegistration()"
        >{{ submitRegisterBtnText }}</v-btn>
      </v-stepper-content>

      <v-stepper-step step="5" :complete="e13 > 5">
        <h4>Registration Confirmation</h4>
        <small>Final step</small>
      </v-stepper-step>
      <v-stepper-content step="5">
        <v-card class="mx-auto rounded-0 mt-3 mb-1" outlined>
          <div class="uploads p-3">
            <div class="text-h5 primary white--text">
              <v-icon class="cong-icon">mdi-check-circle</v-icon>Congratulations
            </div>
            <p>
              Your data has been submitted and your account creation process has
              began !<br />
              We will send you a notification email on how to finalize the
              account creation.
            </p>
          </div>
        </v-card>
      </v-stepper-content>
    </v-stepper>
    <v-snackbar v-model="snackbar">
      {{ snackbartext }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped>
.cong-icon {
  color: white !important;
  font-size: 60px !important;
}
.img-upload {
  max-width: 500px;
  width: 100% !important;
  max-height: 300px;
  height: 100% !important;
}

h1 {
  font-size: 30px !important;
  font-weight: bolder;
}
.createAcct {
  height: 65px !important;
  padding: 15px;
  border-left: 5px solid #329332;
}
</style>

<script>
import AWS from 'aws-sdk'
import httpServ from '../services/HTTPService'
import tts from '../services/TTSService'

export default {
  components: {
    //Uploads
  },
  data() {
    return {
      // eslint-disable-next-line comma-dangle
      accessKeyId: 'accessKeyId',
      secretAccessKey: 'secretAccessKey',
      wasabiEndpoint: new AWS.Endpoint('s3.wasabisys.com'),
      bucketName: 'myBucket',
      e13: 1,
      date_of_incorporation: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      modal: false,
      valid: false,
      uploadercac: [],
      uploaderpassport: [],
      uploadergovid: [],
      imagerules: [
        (v) => !!v || 'File is required',
        (v) => (v && v.size > 0) || 'File is required'
      ],
      snackbar: false,
      snackbartext: '',
      ourCACpic: '',
      ourIDpic: '',
      ourPassportpic: '',
      membershipid: '',
      uploadcount: [],
      readyToSubmitStatus: false,
      CAC_certificate_ext: '',
      ID_card_ext: '',
      passport_photo_ext: '',
      submitRegisterBtnStatus: false,
      submitRegisterBtnText: 'Yes, Finalize Registration'
    }
  },
  watch: {},
  created() {
    const userDetails = httpServ.getSessionJson('storedUserData')

    this.membershipid = userDetails.membership_id
    this.loadPictures(userDetails )
  },
  methods: {
    loadPictures: function (userDetails ) {
      if (userDetails.CAC_certificate_ext !== null) {
        this.ourOldCACpic =
          process.env.VUE_APP_WASABI_BASE_URL +
          'nfmp/CAC/' +
          this.membershipid +
          '.' +
          userDetails.CAC_certificate_ext
        this.onCACFileChanged(this.ourOldCACpic)
        //console.log(this.ourOldCACpic)
      }
    },
    backtoFirstStep: function () {
      this.e13 = 1
      window.scrollTo(0, 0)
    },
    backtoSecondStep: function () {
      this.e13 = 2
      window.scrollTo(0, 0)
    },
    backtoThirdStep: function () {
      this.e13 = 3
      window.scrollTo(0, 0)
      this.submitRegisterBtnStatus = false
      this.submitRegisterBtnText = 'Submitting...'
    },
    SubmitRegistration: function () {
      const sendData = {
        membership_id: this.membershipid,
        CAC_certificate_ext: this.CAC_certificate_ext,
        ID_card_ext: this.ID_card_ext,
        passport_photo_ext: this.passport_photo_ext
      }

      this.submitRegisterBtnStatus = true
      this.submitRegisterBtnText = 'Submitting...'
      const authenUrl = httpServ.getAuthenUrl('/members/saveDocument')
      const resValue = httpServ.makeRequest(authenUrl, 'POST', sendData)

      resValue.then((result) => {
        //console.log('our result is ', result)
        if (result.status === 200) {
          this.snackbar = true
          this.snackbartext = 'Account created Successfully'
          this.e13 = 5
          let speech =
            'Your data has been submitted and your account creation process has began!'

          speech +=
            'We will send you a notification email on how to finalize the account creation. Thank you for registering with us'
          tts.speak(speech)
          //this.submitRegisterBtnText = 'Account Created'
        } else {
          this.signupBtnStatus = false
          this.signupBtnText = 'Submit'
          this.snackbar = true
          this.snackbartext = result.data.error
        }
      })
    },
    UploadCAC: function () {
      if (this.$refs.uploadcacform.validate()) {
        const send_data = {
          ourfile: this.uploadercac,
          ourendpoint:
            process.env.AWS_ENDPOINT || process.env.VUE_APP_AWS_ENDPOINT,
          ouraccessKeyId:
            process.env.AWS_ACCESSKEYID || process.env.VUE_APP_AWS_ACCESSKEYID,
          oursecretAccessKey:
            process.env.AWS_SECRETACCESSKEY ||
            process.env.VUE_APP_AWS_SECRETACCESSKEY,
          ourregion: process.env.AWS_REGION || process.env.VUE_APP_AWS_REGION,
          ourfolder:
            process.env.USER_FOLDER || process.env.VUE_APP_AWS_USER_FOLDER,
          ourbucket: process.env.AWS_BUCKET || process.env.VUE_APP_AWS_BUCKET,
          membership_id: this.membershipid
        }
        const cntl = 'picture_upload0'
        let folder = ''

        switch (cntl) {
        case 'picture_upload0':
          folder = 'CAC/'
          break
        case 'picture_upload1':
          folder = 'GOVT-ID/'
          break
        case 'picture_upload2':
          folder = 'PASSPORT/'
          break
        }

        const f = send_data.ourfile
        const fileName = f.name
        const ext = fileName.split('.').pop()

        this.CAC_certificate_ext = ext

        const s3 = new AWS.S3({
          correctClockSkew: true,
          endpoint: send_data.ourendpoint,
          accessKeyId: send_data.ouraccessKeyId,
          secretAccessKey: send_data.oursecretAccessKey,
          region: send_data.ourregion,
          logger: console
        })
        const uploadRequest = new AWS.S3.ManagedUpload({
          params: {
            Bucket: 'nfmp',
            Key: folder + send_data.membership_id + '.' + ext,
            Body: f
          },
          service: s3
        })

        uploadRequest.on('httpUploadProgress', (event) => {
          const progressPercentage = Math.floor(
            (event.loaded * 100) / event.total
          )

          console.log('Upload progress ' + progressPercentage)
        })

        uploadRequest.send(function (err) {
          if (err) {
            console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2))
            this.snackbar = true
            this.snackbartext = 'CAC Upload error'
          } else {
            console.log('Good upload', this.e13)
          }
        })

        this.e13 = 2
        window.scrollTo(0, 0)
      } else {
        this.uploadcount.push('3')
        this.snackbar = true
        this.snackbartext = 'Upload valid CAC image'
      }
    },

    SendYourID: function () {
      if (this.$refs.sendyouridform.validate()) {
        const send_data = {
          ourfile: this.uploadergovid,
          ourendpoint: process.env.VUE_APP_AWS_ENDPOINT,
          ouraccessKeyId: process.env.VUE_APP_AWS_ACCESSKEYID,
          oursecretAccessKey: process.env.VUE_APP_AWS_SECRETACCESSKEY,
          ourregion: process.env.VUE_APP_AWS_REGION,
          ourfolder: process.env.VUE_APP_AWS_USER_FOLDER,
          ourbucket: process.env.VUE_APP_AWS_BUCKET,
          membership_id: this.membershipid
        }

        const cntl = 'picture_upload1'

        let folder = ''

        switch (cntl) {
        case 'picture_upload0':
          folder = 'CAC/'
          break
        case 'picture_upload1':
          folder = 'GOVT-ID/'
          break
        case 'picture_upload2':
          folder = 'PASSPORT/'
          break
        }

        const f = send_data.ourfile
        const fileName = f.name
        const ext = fileName.split('.').pop()

        this.ID_card_ext = ext
        const s3 = new AWS.S3({
          correctClockSkew: true,
          endpoint: send_data.ourendpoint,
          accessKeyId: send_data.ouraccessKeyId,
          secretAccessKey: send_data.oursecretAccessKey,
          region: send_data.ourregion,
          logger: console
        })
        const uploadRequest = new AWS.S3.ManagedUpload({
          params: {
            Bucket: 'nfmp',
            Key: folder + send_data.membership_id + '.' + ext,
            Body: f
          },
          service: s3
        })

        uploadRequest.on('httpUploadProgress', (event) => {
          const progressPercentage = Math.floor(
            (event.loaded * 100) / event.total
          )

          console.log('Upload progress ' + progressPercentage)
        })

        uploadRequest.send(function (err) {
          if (err) {
            console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2))
            this.snackbar = true
            this.snackbartext = 'ID Upload error'
          } else {
            console.log('Good upload')
          }
        })
        this.e13 = 3
        window.scrollTo(0, 0)
      } else {
        this.snackbar = true
        this.snackbartext = 'Upload valid Government Issued ID'
      }
    },
    UploadGovID: function () {
      console.log('we are here ')
      if (this.$refs.uploadgovidform.validate()) {
        this.e13 = 3
        window.scrollTo(0, 0)
      } else {
        this.uploadcount.push('3')
        this.snackbar = true
        this.snackbartext = 'Upload valid Government Issued ID'
      }
    },

    UploadPassport: function () {
      if (this.$refs.uploadpassportform.validate()) {
        const send_data = {
          ourfile: this.uploaderpassport,
          ourendpoint: process.env.VUE_APP_AWS_ENDPOINT,
          ouraccessKeyId: process.env.VUE_APP_AWS_ACCESSKEYID,
          oursecretAccessKey: process.env.VUE_APP_AWS_SECRETACCESSKEY,
          ourregion: process.env.VUE_APP_AWS_REGION,
          ourfolder: process.env.VUE_APP_AWS_USER_FOLDER,
          ourbucket: process.env.VUE_APP_AWS_BUCKET,
          membership_id: this.membershipid
        }

        const cntl = 'picture_upload2'

        let folder = ''

        switch (cntl) {
        case 'picture_upload0':
          folder = 'CAC/'
          break
        case 'picture_upload1':
          folder = 'GOVT-ID/'
          break
        case 'picture_upload2':
          folder = 'PASSPORT/'
          break
        }

        const f = send_data.ourfile
        const fileName = f.name

        const ext = fileName.split('.').pop()

        this.passport_photo_ext = ext
        const s3 = new AWS.S3({
          correctClockSkew: true,
          endpoint: send_data.ourendpoint,
          accessKeyId: send_data.ouraccessKeyId,
          secretAccessKey: send_data.oursecretAccessKey,
          region: send_data.ourregion,
          logger: console
        })
        const uploadRequest = new AWS.S3.ManagedUpload({
          params: {
            Bucket: 'nfmp',
            Key: folder + send_data.membership_id + '.' + ext,
            Body: f
          },
          service: s3
        })

        uploadRequest.on('httpUploadProgress', (event) => {
          const progressPercentage = Math.floor(
            (event.loaded * 100) / event.total
          )

          console.log('Upload progress ' + progressPercentage)
        })

        uploadRequest.send(function (err) {
          if (err) {
            console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2))
            this.snackbar = true
            this.snackbartext = 'Passport Upload error'
          } else {
            console.log('Good upload')
          }
        })
        this.e13 = 4
      } else {
        this.snackbar = true
        this.snackbartext = 'Upload a valid Passport'
      }
    },
    onCACFileChanged: function (file) {
      //this.ourCACpic = URL.createObjectURL(binaryData)
      const reader = new FileReader()

      reader.onload = (e) => {
        this.ourCACpic = e.target.result
      }
      reader.readAsDataURL(file)
      //this.$emit('input', file[0])
    },

    onIDFileChanged: function (file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.ourIDpic = e.target.result
      }
      reader.readAsDataURL(file)
    },

    onPassportFileChanged: function (file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.ourPassportpic = e.target.result
      }
      reader.readAsDataURL(file)
    },

    SubmitUploads: function () {
      //
    }
  }
  // methods: {
  //   validate () {
  //     this.$refs.form.validate()
  //   }
  // }

  // eslint-disable-next-line comma-dangle
}
</script>