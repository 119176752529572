<template>
  <div class="white">
    <v-container>
      <div class="branch p-3">
        <h3>Whistle blowing
        </h3>
      </div>
      <v-card
        class="mx-auto grey lighten-3 mt-4 shadow-card"
      >
        <v-card-text>
          <div>To serve you better, we want you to report fraud and inappropriate activities. We also want to protect your identity. Therefore help us to help you by providing accurate data for us to stop and apprehend offenders</div>
        </v-card-text>
      
      </v-card>
      <v-form 
        ref="form"
        v-model="valid" 
        class="mt-2"
      >
        <v-select
          v-model="natureOfConductId"
          return-object
          item-text="title"
          item-value="id"
          :items="natureOfConductItems"
          :rules="[v => !!v || 'Item is required']"
          label="Nature Of Conduct *"
          required
        ></v-select>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="stateOfConductId"
              return-object
              item-text="title"
              item-value="id"
              :items="stateOfConductItems"
              :rules="[v => !!v || 'Item is required']"
              label="State where is conduct occurs *"
              required
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="lgaOfConductId"
              return-object
              item-text="title"
              item-value="id"
              :items="lgaOfConductItems"
              :rules="[v => !!v || 'Item is required']"
              label="LGA where is conduct occurs *"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-text-field
          v-model="address"
          :rules="addressRules"
          label="Address where is conduct occurs *"
          placeholder="Address"
        ></v-text-field>
        <v-text-field
          v-model="observation"
          :rules="observationRules"
          label="Your observation*"
          placeholder=" observation*"
        ></v-text-field>
           
        <div class="d-flex justify-content-center">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Your Email"
            type="email"
            placeholder="abcd@gmail.com"
          ></v-text-field>
          <v-text-field
            v-model="phoneNumber"
            :rules="phoneRules"
            label="Phone number"
            placeholder="08168815522"
            type="number"
            class="ml-3"
          ></v-text-field>
        </div>
        <div class="d-flex justify-content-center">
          <v-btn
            submit
            class="primary p-4 mt-2 white--text rounded-0"
            @click="validate"
          > Submit </v-btn>
        </div>
      </v-form></v-container>
  </div>

</template>

<script>

//import myServices from '../../../assets/services/myservices'
import httpServ from '../services/HTTPService'

export default {
  data: () => ({
    valid: true,
    companyName: '',
    companyNameRules: [
      (v) => !!v || 'Company Name is required',
      (v) => (v && v.length <= 30) || 'Name must be less than 30 characters'
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    cac: '',
    cacRules: [
      (v) => !!v || 'CAC Number is required',
      (v) => (v && v.length <= 11) || 'Name must be less than 11 characters'
    ],
    observation: '',
    observationRules: [
      (v) => !!v || 'Observation is required',
      (v) => (v && v.length <= 50) || 'Name must be less than 50 characters'
    ],
    address: '',
    addressRules: [
      (v) => !!v || 'Commpany Address is required',
      (v) => (v && v.length <= 35) || 'Commpany Address must be less than 35 characters'
    ],
    phoneNumber: '',
    phoneRules: [
      (v) => !!v || 'Phone Number is required',
      (v) => (v && v.length <= 11) || 'Phone Number must be 11 figures'
    ],
    select: null,
    natureOfConductId: 0,
    natureOfConductItems: [],
    stateOfConductId: 0,
    stateOfConductItems: [],
    lgaOfConductId: 0,
    lgaOfConductItems: [],
    alllga:[]
    // checkbox: false
  }),
  watch: {
    stateOfConductId: {
      handler(newvalue) {
        //////console.log('item changed', newvalue)
        //////console.log('state id is ', this.stateId)
        this.lgaOfConductItems = this.alllga.filter((lga) => lga.state_id === this.stateOfConductId.id)
        //////console.log('all the lga is ', this.statelgas)
      },
      immediate: true
    }
  },
  created() {
    
    //const params = { name: 'cyril', LOB: '', state: '' }
    //const res_value = myServices.getSignupData()
    //const res_value = myServices.getWhistleBlowingData()
    const formOptionsUrl = httpServ.getGuestUrl('/formOptions/whistleblowing')
    const resValue = httpServ.makeRequest(formOptionsUrl, 'GET')

    resValue.then( (result) => {
      console.log('our result is ', result)
      if (result.status === 200) {     
        this.natureOfConductItems = result.data.nature_of_conduct_rf
        this.stateOfConductItems = result.data.state_rf
        this.alllga = result.data.lga_rf
        
      } else {
        /* this.snackbartext = result.message
        this.snackbar = true
        this.signupBtnStatus = false
        this.signupBtnText = 'Create Account' */
      }
      //////console.log('our response value is ', result)
    })
  },
  methods: {
    validate () {
      //this.$refs.form.validate()
      alert('This is a Test version, we will be back LIVE')
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    }
  }
}
</script>

<style>

.branch{
  border-left: 4px solid #4CAF50;
}
.shadow-card{
  box-shadow: 3px 3px rgb(179, 179, 179) !important;
}
</style>