<template>
  <div>
    <CreatePassword />
    <!-- <ForgotPassword /> -->
  </div>
</template>

<script>
import CreatePassword from '@/components/ui/landing/signin/CreatePassword.vue'
// import ForgotPassword from '@/components/ui/landing/signin/ForgotPassword.vue'
export default {
  components: {
    CreatePassword
    // ForgotPassword
  }
}
</script>