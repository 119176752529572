<!-- eslint-disable comma-dangle -->
<template>
  <div class="container grey lighten-5">
    <!-- <v-row> -->
    <v-stepper v-model="e13" vertical> 
      <div class="createAcct"> <h1>Update Documents</h1>  </div>
      <v-stepper-step 
        step="1"
        :complete="e13 > 1"
      >
        <h4> CAC Document </h4>
        <small>This document is mandatory</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card class="mb-12 white">
          <v-form
            ref="uploadcacform"
            fast-fail
          >
            <v-card
              class="mx-auto rounded-0"
              outlined
            >
              <div class="uploads secondary p-3">
                <h6 class="text-h6">
                  CAC Certificate
                </h6>
                <p>Upload a scan copy of the company C.A.C Certificates</p>
              </div>
            </v-card>
            <v-file-input
              v-model="uploadercac"
              type="file"
              class="grey lighten-5"
              show-size
              counter
              label="Upload your certificates"
              :rules="imagerules"
              @change="onCACFileChanged"
            ></v-file-input>
            <div v-if="show_old_CAC_certificate_ext == false" class="img_preview cac_preview">
              <v-img class="img-upload" :src="ourCACpic"></v-img>
            </div>
            <div v-if="show_old_CAC_certificate_ext == true" class="img_preview cac_preview">
              <v-img class="img-upload" :src="ourOldCACpic"></v-img>
            </div>
          </v-form>
        </v-card>
        <v-btn 
          color="primary rounded-0" 
          @click="UploadCAC()"
        >Upload New CAC Image</v-btn>
        <!-- <v-btn text @click="e13 = 2" >Back to Step 2</v-btn> -->
      </v-stepper-content>
      
      <v-stepper-step
        step="2"
        :complete="e13 > 2"
      >
        <h4> Government Issued ID </h4>
        <small>The uploads is mandatory</small>
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-form
          ref="sendyouridform"
          v-model="valid"
          fast-fail
        >
          <v-card
            class="mx-auto rounded-0"
            outlined
          >
            <div class="uploads secondary p-3">
              <h6 class="text-h6">
                Government Issued ID
              </h6>
              <p>Upload a copy of your Government issued ID</p>
            </div>
          </v-card>
          <v-file-input
            v-model="uploadergovid"
            type="file"
            class="grey lighten-5"
            show-size
            counter
            label="Upload your Government Issued ID"
            
            :rules="imagerules"
            @change="onIDFileChanged"
          ></v-file-input>
          <div v-if="show_old_ID_card_ext == false" class="img_preview cac_preview">
            <v-img class="img-upload" :src="ourIDpic"></v-img>
          </div>
          <div v-if="show_old_ID_card_ext == true" class="img_preview cac_preview">
            <v-img class="img-upload" :src="ourOldIDpic"></v-img>
          </div>
        </v-form>
        <v-btn text @click="backtoFirstStep()" >Back to Step 1</v-btn> &nbsp;
        <v-btn
          color="primary rounded-0" 
          @click="SendYourID()"
        >Upload New ID</v-btn>
      </v-stepper-content>

      <v-stepper-step
        step="3"
        :complete="e13 > 3"
      >
        <h4> Passport Photograph </h4>
        <small>This document is mandatory</small>
      </v-stepper-step>
      <v-stepper-content step="3">
        <v-form
          ref="uploadpassportform"
          fast-fail
        >
          <v-card
            class="mx-auto rounded-0 mt-3"
            outlined
          >
            <div class="uploads secondary p-3">
              <h6 class="text-h6">
                Passport Photograph
              </h6>
              <p>Upload a scan copy of your passport photograph</p>
            </div>
          </v-card>
          <v-file-input
            v-model="uploaderpassport"
            type="file"
            class="grey lighten-5"
            show-size
            counter
            label="Upload your passport"
            :rules="imagerules"
            @change="onPassportFileChanged"
          ></v-file-input>
          <div v-if="show_old_passport_photo_ext == false" class="img_preview passport_preview mb-1">
            <v-img class="img-upload" :src="ourPassportpic"></v-img>
          </div>
          <div v-if="show_old_passport_photo_ext == true" class="img_preview passport_preview mb-1">
            <v-img class="" :src="ourOldPassportpic"></v-img>
          </div>
        </v-form>
        <v-btn text @click="backtoSecondStep()" >Back to Step 2</v-btn> &nbsp; &nbsp;
        <v-btn 
          color="primary rounded-0" 
          @click="UploadPassport()"
        >Upload New Passport</v-btn>
      </v-stepper-content>
      
      <v-stepper-step
        step="4"
        :complete="e13 > 4"
      >
        <h4> Final Stage </h4>
        <small>Final view</small>
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-form
          ref="uploadgovidform"
          fast-fail
        >
          <v-card
            class="mx-auto rounded-0 mt-3 mb-1"
            outlined
          >
            <div class="uploads p-3">
              <h6 class="text-h6">
                Please confirm that the information uploaded are correct ?
              </h6>
              <p>If information are correct, use the button below to finish your registration</p>
            </div>
          </v-card>
        </v-form>
        <v-btn text @click="backtoThirdStep()" >Back to Step 3</v-btn> &nbsp; &nbsp;
        <v-btn 
          color="primary rounded-0" 
          :disabled="submitRegisterBtnStatus"
          @click="SubmitRegistration()"
        >{{ submitRegisterBtnText }}</v-btn>
      </v-stepper-content>

      <v-stepper-step
        step="5"
        :complete="e13 > 5"
      >
        <h4> Congratulation Stage </h4>
        <small>If you are here, that means you made it.</small>
      </v-stepper-step>
      <v-stepper-content step="5">
        <v-card
          class="mx-auto rounded-0 mt-3 mb-1"
          outlined
        >
          <div class="uploads p-3">
            <div class="text-h5 primary white--text">
              <v-icon class="cong-icon">mdi-check-circle</v-icon>Update Successful 
            </div>
            <p>
              Your Account has been updated and.<br/>
              We will send you a notification email.
            </p>
          </div>
        </v-card>
      </v-stepper-content>
    </v-stepper>
    <v-snackbar
      v-model="snackbar"
    >
      {{ snackbartext }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div> 
</template>

<style scoped>
.cong-icon {
  color: white !important;
  font-size: 60px !important;
}
.img-upload {
  max-width: 500px;
  width: 100% !important;
  max-height: 300px;
  height: 100% !important;
}

h1{
    font-size: 30px !important;
    font-weight: bolder;
}
.createAcct{
  height: 65px !important;
  padding: 15px;
  border-left: 5px solid #329332 ;
}
</style>

<script>
//import Uploads from '@/components/ui/landing/signup/Uploads.vue'
import AWS from 'aws-sdk'
import myServices from '../../../../assets/services/myservices'

export default {
  components: {
    //Uploads
  },
  data () {
    return {
      // eslint-disable-next-line comma-dangle
      accessKeyId: 'accessKeyId',
      secretAccessKey: 'secretAccessKey',
      wasabiEndpoint: new AWS.Endpoint('s3.wasabisys.com'),
      bucketName: 'myBucket',
      e13: 1,
      date_of_incorporation: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      valid: false,
      uploadercac: [],
      uploaderpassport: [],
      uploadergovid: [],
      imagerules: [
        (v) => !!v || 'File is required',
        (v) => (v && v.size > 0) || 'File is required'
      ],
      snackbar: false,
      snackbartext: '',
      ourCACpic: '',
      ourIDpic: '',
      ourPassportpic: '',
      ourOldCACpic: 'https://cdn.vuetifyjs.com/images/profiles/marcus.jpg',
      ourOldIDpic: 'https://cdn.vuetifyjs.com/images/profiles/marcus.jpg',
      ourOldPassportpic: 'https://cdn.vuetifyjs.com/images/profiles/marcus.jpg',
      membershipid: '',
      uploadcount: [],
      readyToSubmitStatus: false,
      CAC_certificate_ext: '',
      ID_card_ext: '',
      passport_photo_ext: '',
      submitRegisterBtnStatus: false,
      submitRegisterBtnText: 'Yes, Submit Registration',
      userProfile: {},
      show_old_CAC_certificate_ext: true,
      show_old_ID_card_ext: true,
      show_old_passport_photo_ext: true,
      wasabi_base_url: process.env.VUE_APP_WASABI_BASE_URL,
      cac_base_url: 'nfmp/CAC/',
      govtid_base_url: 'nfmp/GOVT-ID/',
      passport_base_url: 'nfmp/PASSPORT/'
    }
  },
  watch: {
    
  },
  created() {
    this.membershipid = this.$route.params.membershipid
    //const res_value = myServices.getSignupData()
    /* const res_value = myServices.getCreateAccountData()
    
    res_value.then( (result) => {
      console.log('our result is ', result)
      if (result.status === 200) {     
        this.mainBusItems1 = result.data.main_line_of_business_rf
        this.secBusItems2 = result.data.main_line_of_business_rf
        this.companyItems = result.data.company_ownership_type_rf
        this.residentItems = result.data.residence_state_rf
        this.alllga = result.data.residence_lga_rf
      } else {
      
      }
    }) */
  },
  mounted () {
    this.userProfile = myServices.getSessionItem('process_data')
    console.log('this is our session data ', myServices.getSessionItem('process_data'))
    this.ourOldCACpic = process.env.VUE_APP_WASABI_BASE_URL + this.cac_base_url + this.userProfile.data.membership_id + '.' + this.userProfile.data.CAC_certificate_ext
    this.ourOldIDpic = process.env.VUE_APP_WASABI_BASE_URL + this.govtid_base_url + this.userProfile.data.membership_id + '.' + this.userProfile.data.ID_card_ext
    this.ourOldPassportpic = process.env.VUE_APP_WASABI_BASE_URL + this.passport_base_url + this.userProfile.data.membership_id + '.' + this.userProfile.data.passport_photo_ext
    console.log('our old CAC is ', this.ourOldCACpic)
    console.log('our old ID is ', this.ourOldIDpic)
    
  },
  methods: {
    backtoFirstStep: function() {
      this.e13 = 1
      window.scrollTo(0,0)
    },
    backtoSecondStep: function() {
      this.e13 = 2
      window.scrollTo(0,0)
    },
    backtoThirdStep: function() {
      this.e13 = 3
      window.scrollTo(0,0)
    },
    SubmitRegistration: function () {
      const send_data = {
        membership_id: this.membershipid,
        CAC_certificate_ext: this.CAC_certificate_ext,
        ID_card_ext: this.ID_card_ext,
        passport_photo_ext: this.passport_photo_ext
      }
      
      this.submitRegisterBtnStatus = true
      this.submitRegisterBtnText = 'Submitting...'
      const res_value = myServices.saveDocument(send_data)
      //const res_value = myServices.getProducts()

      res_value.then( (result) => {
        console.log('our result is ', result)
        if (result.status === 200) {     
          this.snackbar = true
          this.snackbartext = 'Account created Successfully'
            
          this.e13 = 5
          
          this.submitRegisterBtnText = 'Account Created'
        } else {
          this.signupBtnStatus = false
          this.signupBtnText = 'Submit'
          this.snackbar = true
          this.snackbartext = result.data.error
        }
      })
    },
    UploadCACProceed: function () {
      this.e13 = 2
      window.scrollTo(0,0)
      this.show_old_ID_card_ext = true
    },
    UploadCAC: function () {
      if (this.$refs.uploadcacform.validate()) {
        const send_data = {
          ourfile: this.uploadercac,
          ourendpoint: process.env.VUE_APP_AWS_ENDPOINT,
          ouraccessKeyId: process.env.VUE_APP_AWS_ACCESSKEYID,
          oursecretAccessKey: process.env.VUE_APP_AWS_SECRETACCESSKEY,
          ourregion: process.env.VUE_APP_AWS_REGION,
          ourfolder: process.env.VUE_APP_AWS_USER_FOLDER,
          ourbucket: process.env.VUE_APP_AWS_BUCKET,
          membership_id: this.membershipid
        }

        const cntl = 'picture_upload0'

        let folder = ''

        switch (cntl) {
        case 'picture_upload0':
          folder = 'CAC/'
          break
        case 'picture_upload1':
          folder = 'GOVT-ID/'
          break
        case 'picture_upload2':
          folder = 'PASSPORT/'
          break
        }

        const f = send_data.ourfile
        const fileName = f.name
        const ext = fileName.split('.').pop()
        
        this.CAC_certificate_ext = ext
        
        const s3 = new AWS.S3({
          correctClockSkew: true,
          endpoint: send_data.ourendpoint,
          accessKeyId: send_data.ouraccessKeyId,
          secretAccessKey: send_data.oursecretAccessKey,
          region: send_data.ourregion,
          logger: console
        })
        const uploadRequest = new AWS.S3.ManagedUpload({
          params: { Bucket: 'nfmp', Key: folder + send_data.membership_id + '.' + ext, Body: f },
          service: s3
        })

        uploadRequest.on('httpUploadProgress', (event) => {
          const progressPercentage = Math.floor(event.loaded * 100 / event.total)

          console.log('Upload progress ' + progressPercentage)
        })

        uploadRequest.send(function (err) {
          if (err) {
            console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2))
            this.snackbar = true
            this.snackbartext = 'CAC Upload error'    
          } else {
            console.log('Good upload', this.e13)
          }
        })

        this.e13 = 2
        window.scrollTo(0,0)
      } else {
        this.uploadcount.push('3')
        this.snackbar = true
        this.snackbartext = 'Upload valid CAC image'
      }
    },

    SendYourIDProceed: function () {
      this.e13 = 3
      window.scrollTo(0,0)
    },
    SendYourID: function () {
      if (this.$refs.sendyouridform.validate()) {
        const send_data = {
          ourfile: this.uploadergovid,
          ourendpoint: process.env.VUE_APP_AWS_ENDPOINT,
          ouraccessKeyId: process.env.VUE_APP_AWS_ACCESSKEYID,
          oursecretAccessKey: process.env.VUE_APP_AWS_SECRETACCESSKEY,
          ourregion: process.env.VUE_APP_AWS_REGION,
          ourfolder: process.env.VUE_APP_AWS_USER_FOLDER,
          ourbucket: process.env.VUE_APP_AWS_BUCKET,
          membership_id: this.membershipid
        }

        const cntl = 'picture_upload1'

        let folder = ''

        switch (cntl) {
        case 'picture_upload0':
          folder = 'CAC/'
          break
        case 'picture_upload1':
          folder = 'GOVT-ID/'
          break
        case 'picture_upload2':
          folder = 'PASSPORT/'
          break
        }

        const f = send_data.ourfile
        const fileName = f.name
        const ext = fileName.split('.').pop()

        this.ID_card_ext = ext
        const s3 = new AWS.S3({
          correctClockSkew: true,
          endpoint: send_data.ourendpoint,
          accessKeyId: send_data.ouraccessKeyId,
          secretAccessKey: send_data.oursecretAccessKey,
          region: send_data.ourregion,
          logger: console
        })
        const uploadRequest = new AWS.S3.ManagedUpload({
          params: { Bucket: 'nfmp', Key: folder + send_data.membership_id + '.' + ext, Body: f },
          service: s3
        })

        uploadRequest.on('httpUploadProgress', (event) => {
          const progressPercentage = Math.floor(event.loaded * 100 / event.total)

          console.log('Upload progress ' + progressPercentage)
        })

        uploadRequest.send(function (err) {
          if (err) {
            console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2))
            this.snackbar = true
            this.snackbartext = 'ID Upload error'    
          } else {
            console.log('Good upload')
          }
        })
        this.e13 = 3
        window.scrollTo(0,0)
      } else {
        this.snackbar = true
        this.snackbartext = 'Upload valid Government Issued ID'
      }
    },
    UploadGovID: function () {
      console.log('we are here ')
      if (this.$refs.uploadgovidform.validate()) {
        this.e13 = 3
        window.scrollTo(0,0)
      } else {
        this.uploadcount.push('3')
        this.snackbar = true
        this.snackbartext = 'Upload valid Government Issued ID'
      }
    },

    UploadPassportProceed: function () {
      this.e13 = 4
    },
    
    UploadPassport: function () {
      if (this.$refs.uploadpassportform.validate()) {
        const send_data = {
          ourfile: this.uploaderpassport,
          ourendpoint: process.env.VUE_APP_AWS_ENDPOINT,
          ouraccessKeyId: process.env.VUE_APP_AWS_ACCESSKEYID,
          oursecretAccessKey: process.env.VUE_APP_AWS_SECRETACCESSKEY,
          ourregion: process.env.VUE_APP_AWS_REGION,
          ourfolder: process.env.VUE_APP_AWS_USER_FOLDER,
          ourbucket: process.env.VUE_APP_AWS_BUCKET,
          membership_id: this.membershipid
        }

        const cntl = 'picture_upload2'

        let folder = ''

        switch (cntl) {
        case 'picture_upload0':
          folder = 'CAC/'
          break
        case 'picture_upload1':
          folder = 'GOVT-ID/'
          break
        case 'picture_upload2':
          folder = 'PASSPORT/'
          break
        }

        const f = send_data.ourfile
        const fileName = f.name

        const ext = fileName.split('.').pop()
        
        this.passport_photo_ext = ext
        const s3 = new AWS.S3({
          correctClockSkew: true,
          endpoint: send_data.ourendpoint,
          accessKeyId: send_data.ouraccessKeyId,
          secretAccessKey: send_data.oursecretAccessKey,
          region: send_data.ourregion,
          logger: console
        })
        const uploadRequest = new AWS.S3.ManagedUpload({
          params: { Bucket: 'nfmp', Key: folder + send_data.membership_id + '.' + ext, Body: f },
          service: s3
        })

        uploadRequest.on('httpUploadProgress', (event) => {
          const progressPercentage = Math.floor(event.loaded * 100 / event.total)

          console.log('Upload progress ' + progressPercentage)
        })

        uploadRequest.send(function (err) {
          if (err) {
            console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2))
            this.snackbar = true
            this.snackbartext = 'Passport Upload error'    
          } else {
            console.log('Good upload')
          }
        })
        this.e13 = 4
      } else {
        this.snackbar = true
        this.snackbartext = 'Upload a valid Passport'
      }
    },
    onCACFileChanged: function( file ) {
      //this.ourCACpic = URL.createObjectURL(binaryData)
      this.show_old_CAC_certificate_ext = false
      const reader = new FileReader()

      reader.onload = (e) => {
        this.ourCACpic = e.target.result
      }
      reader.readAsDataURL(file)
      //this.$emit('input', file[0])
    },
    
    onIDFileChanged: function(file) {
      this.show_old_ID_card_ext = false

      const reader = new FileReader()

      reader.onload = (e) => {
        this.ourIDpic = e.target.result
      }
      reader.readAsDataURL(file)
    },

    onPassportFileChanged: function(file) {
      this.show_old_passport_photo_ext = false
      
      const reader = new FileReader()

      reader.onload = (e) => {
        this.ourPassportpic = e.target.result
      }
      reader.readAsDataURL(file)
    },

    SubmitUploads: function() {
      //
    }
  }
  // methods: {
  //   validate () {
  //     this.$refs.form.validate()
  //   }
  // }
    
  // eslint-disable-next-line comma-dangle
}
</script>