export default {
  getGuestUrl: function (path) {
    const GUEST_BASE_URL = process.env.VUE_APP_GUEST_API_BASE_URL || 'http://localhost/new-fiss/guest-api'

    const url = GUEST_BASE_URL + path

    console.log(url)

    return url
  },
  getAuthenUrl: function (path) {
    const GUEST_BASE_URL = process.env.VUE_APP_AUTHENICATION_API_BASE_URL || 'http://localhost/new-fiss/authen-api'

    const url = GUEST_BASE_URL + path

    return url
  },
  makeRequest: function (ourUrl, method, params = {}) {
    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    const options = {
      headers: { 'Content-Type': 'application/json' }
    }

    if (method === 'GET') {
      options.method = 'GET'
      if (params !== null)
        ourUrl += '?' + (new URLSearchParams(params)).toString()
    }

    if (method === 'POST') {
      options.body = JSON.stringify(params)
      options.method = 'POST'
    }
    //return fetch( url, options ).then( response => response.json() )
    //console.log('our options are ', options)

    return fetch(ourUrl, options)
      .then(async (response) => {
        const data = await response.json()
        //console.log('this is our response', response)

        responseValue['status'] = response.status
        responseValue['message'] = data.message
        responseValue['data'] = data.data

        //console.log('our response is ', responseValue)
        return responseValue
      })
      .catch((error) => {
        //this.errorMessage = error
        console.error(error)
        responseValue['status'] = response.status
        responseValue['message'] = error
        responseValue['data'] = error

        return responseValue
      })
  },
  setSessionItem (key, value) {
    localStorage.setItem(key, value)
  },
  setSessionJson (key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  getSessionItem (key) {
    return localStorage.getItem(key)
  },
  getSessionJson (key) {
    return JSON.parse(localStorage.getItem(key))
  },
  clearSession() {
    localStorage.clear()
  },

  logoutUser() {
    this.clearSession()

    return true
  },
  scrollTo(data) {
    if (data === 'top') {
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }, 500)
    }
    if (data === 'bottom') {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}