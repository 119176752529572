<template>
  <div>
    <Toolbar />
    <Carousel />
    <Welcome />
    <Features />
    <OtherPlatforms />
    <OneStep />
    <HomeGuide />
    <FooterBar />
  </div>
</template>

<script>
import Toolbar from '@/nfmp_components/domain_components/Toolbar.vue'
import Carousel from '@/nfmp_components/top_components/Carousel.vue'
import Welcome from '@/nfmp_components/top_components/Welcome.vue'
import HomeGuide from '@/nfmp_components/top_components/HomeGuide.vue'
import OneStep from '@/nfmp_components/top_components/OneStep.vue'
import Features from '@/nfmp_components/top_components/Features.vue'
import OtherPlatforms from '@/nfmp_components/top_components/OtherPlatforms.vue'
import FooterBar from '@/nfmp_components/domain_components/Footer.vue'

export default {
  components: {
    Toolbar,
    Carousel,
    Welcome,
    HomeGuide,
    OneStep,
    Features,
    OtherPlatforms,
    FooterBar
  }
}
</script>
