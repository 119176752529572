export const menu1 = [{
  title: 'Solutions',
  link: '#solutions'
}, {
  title: 'Pricing',
  link: '#pricing'
}, {
  title: 'Docs',
  link: '#docs'
}, {
  title: 'More',
  link: '#more'
}]

export const menu2 = [{
  title: 'About FISSD',
  link: '/FISS_Department'
},{
  title: 'Partners',
  link: '/partner'
},
{
  title: 'Products',
  link: '/products'
},
{
  title: 'Farmers Platform',
  link: '/#'
},
{
  title: 'Import/Export Hub',
  link: '/##'
},
{
  title: 'Dealers Listing',
  link: '/dealersListing'
},
{
  title: 'Guidelines',
  link: '/guidelines'
},
{
  title: 'Agro Buckets',
  link: '/#'
}
]

export default {
  menu1,
  menu2
}
