<template>
  <v-footer color="transparent" class="footer-bg">
    <v-container class="py-5">
      <v-row>
        <v-col cols="12" md="4">
          <div class="dimg ">
            <h5 class="text-white mb-3">Who we Are?</h5>
            <router-link to="#" class="d-flex align-center text-decoration-none mr-2">
              <img
                src="/images/logo.fw.png"
                alt=""
                large
                height="28"
                width="94%"
              >

            </router-link>          </div>            
          <div class="d-flex flex-column flex-lg-row w-full">
            <p class="pt-2" color="white"> The National Fertilizer Management Platform is an online platform developed by the Farm Inputs Support Services(FISS) Department of the Federal Ministry of Agriculture and Rural Development(FMARD), with the aim of providing an end to end value chain for fertilizer industry players and farmers.
            </p>
          </div>
          <!-- <div class="text-center text-md-right mt-4 mt-lg-2">
            Connect
            <v-btn fab small color="secondary" class="ml-2">
              <v-icon>mdi-twitter</v-icon>
            </v-btn>
            <v-btn fab small color="secondary" class="ml-2">
              <v-icon>mdi-linkedin</v-icon>
            </v-btn>
            <v-btn fab small color="secondary" class="ml-2">
              <v-icon>mdi-instagram</v-icon>
            </v-btn>
          </div> -->
        </v-col>
        <v-col cols="12" md="4">

          <div class="text-h6 text-lg-h5 font-weight-bold text-white">Quick links</div>
          <div style="width: 80px; height: 3px" class="mb-5 mt-1 white lighten-2" />
          <div class="d-flex flex-wrap">
            <div v-for="(link, i) in links" :key="i" class="w-half  textCOLOR">
              <router-link
                v-if="link.to"
                class="text-decoration-none textCOLOR text--lighten-2"
                :to="link.to"
              >{{ link.label }}</router-link>
              <a
                v-else
                class="text-decoration-none textCOLOR text--lighten-2"
                :href="link.href"
                :target="link.target || 'blank'"
              >{{ link.label }}</a>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="text-h6 text-lg-h5 font-weight-bold text-white">Our Contact Details</div>
          <div style="width: 80px; height: 3px" class="mb-5 mt-1 white lighten-2" />
          <div class="d-flex mb-2 font-weight-bold text-white">
            <v-icon color="white" class="mr-2">mdi-map-marker-outline</v-icon>
            Bank of Agriculture (BoA) Plaza, 5th Floor Plot 162 Independence Avenue, Central Business District, Abuja
          </div>
          <div class="d-flex mb-2">
            <v-icon color="white" class="mr-2">mdi-phone-outline</v-icon>
            <a href="#" class="text-decoration-none white--text text--lighten-2">+03 000 000 000 555 55</a>
          </div>
          <div class="d-flex mb-2">
            <v-icon color="white" class="mr-2">mdi-email-outline</v-icon>
            <a href="#" class="text-decoration-none white-text text--lighten-2">customercare@nfmp.gov.ng</a>
          </div>
        </v-col>
       
      </v-row>
      <v-divider class="my-3"></v-divider>
      <div class="text-center caption text-white">
        © NFMP 2023. All Rights Reserved
      </div>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          label: 'Home',
          to: '#'
        },
        {
          label: 'Products',
          to: '#'
        },
        {
          label: 'About FISSD',
          to: '#'
        },
        {
          label: 'Import/Export',
          to: '#'
        },
        {
          label: 'Partners',
          to: '#'
        },
        {
          label: 'Agro Buckets',
          to: '#'
        },
        {
          label: 'Guidelines',
          to: '#'
        },
        {
          label: 'Farmer Platform',
          to: '#'
        },
        {
          label: 'Create Account',
          to: '#'
        },
        {
          label: 'Dealers Listing',
          to: '#'
        },
        {
          label: 'Signin',
          to: '#'
        },
        {
          label: 'Help Center',
          to: '#'
        },
        {
          label: 'Contact Us',
          to: '#'
        },
        {
          label: 'Admin Login',
          to: '#'
        },
        {
          label: 'Whistle blowing',
          to: '#'
        },
        {
          label: 'Web mail',
          to: '#'
        }
      ]
    }
  }
}
</script>

<style scoped>
.footer-bg{
  background-color: #353f36 !important;
  color:rgb(17, 16, 16) !important;
}

.textCOLOR{
  color: white !important;
}
p{
  color: white !important;
  font-size: 14px !important;
  font-weight: 300 !important;
}
.subscribe{
  background-color: #4CAF50 !important;
  border-radius: 0px !important;
}
</style>
