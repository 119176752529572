<template>
  <v-container>
    <div class="about pa-2">
      <h3>Fertilizer Organic Products
      </h3>
    </div>

    <v-row class="green lighten-3 my-1 pa-0 rounded-3 ">
      <v-col cols="12" sm="8" md="4" class="py-1">
        <v-text-field
          v-model="name_search"
          label="Name"
          hint="Type Name of Company"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3" class="py-1">
        <v-select
          v-model="formId"
          return-object
          item-text="title"
          item-value="id"
          :items="formItems"
          label="Select Form type of Fertilizer *"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3" class="py-1">
        <v-select
          v-model="stateItemsId"
          return-object
          item-text="title"
          item-value="id"
          :items="stateItems"
          label="Select State *"
        ></v-select>
      </v-col>

      <v-col cols="12" sm="2" class="pa-1">
        <v-btn class="mt-2 rounded-0 primary" @click="makeSearch">
          Search
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="isNotLoaded">
      <v-col cols="12" sm="6" md="4" class="pa-0 ma-0">
        <v-skeleton-loader
          class="mx-auto"
          max-width="360"
          type="list-item-avatar, divider, list-item-three-line, card-heading, image"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="p-0">
        <v-skeleton-loader
          class="mx-auto"
          max-width="360"
          type="list-item-avatar, divider, list-item-three-line, card-heading, image"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="p-0">
        <v-skeleton-loader
          class="mx-auto"
          max-width="360"
          type="list-item-avatar, divider, list-item-three-line, card-heading, image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        v-for="(prod, i) in products"
        :key="i"
        cols="12"
        sm="6"
        md="4"
        class="p-0"
      >
        <v-card class="mx-auto rounded-0 item-card" width="95%">
          <div class="cereal pt-1 pr-2">
            <small class="px-1">
              <label class="yellow--text">Crops:</label> Cotton Cereals
            </small>
            <small class="liquid">
              <label class="yellow--text">Form:</label> Liquid
            </small>
          </div>
          <div class="card3-div">
            <v-card-text class="py-1 px-0">
              <div class="text-center">
                <h6 class="text-center text-bold primary--text t-prod">
                  {{ prod.product }}
                </h6>
                <span class="text-center t-remarks"> {{ prod.remarks }}</span>
              </div>
              <div class="d-flex p-2">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class=""
                    >Nutrient Content</v-expansion-panel-header>
                    <v-expansion-panel-content class="nutrient-data">
                      <v-row>
                        <v-table class="t-table">
                          <thead>
                            <tr>
                              <th class="text-left">Nutrient</th>
                              <th class="text-left">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(productActual, ia) in actual.filter(
                                (nutrient) =>
                                  nutrient.fertilizer_id === prod.id &&
                                  nutrient.value !== ''
                              )"
                              :key="ia"
                            >
                              <td>
                                {{ productActual.nutrient }}
                              </td>
                              <td>
                                {{ productActual.value }}
                              </td>
                            </tr>
                          </tbody>
                        </v-table>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <div class="grey lighten-3 p-3">
                <h6 class="">
                  {{ prod.company_name }}
                </h6>
                <p class="crescent green--text">
                  {{ prod.address }}
                </p>
                <div class="crescent">
                  <v-icon small class="icon-color"> mdi-phone </v-icon>
                  <span> {{ prod.phone }}</span> <br />
                  <v-icon small class="icon-color"> mdi-email </v-icon>
                  <span> {{ prod.email }}</span>
                </div>
              </div>
            </v-card-text>

            <div class="cd-footer pl-4 pb-1">
              <span class="primary--text">Evaluation:</span> &nbsp;
              <br />
              <span
                v-for="(evalLab, ib) in labs.filter(
                  (lab) => lab.product_id === prod.id && lab.laboratory !== ''
                )"
                :key="ib"
              >
                {{ evalLab.laboratory }} - {{ evalLab.analysis_date }} &nbsp;
                <span
                  v-if="
                    ib !=
                      Object.keys(
                        labs.filter(
                          (lab) =>
                            lab.product_id === prod.id && lab.laboratory !== ''
                        )
                      ).length -
                      1
                  "
                  class="bold"
                >|</span>
                  &nbsp;
              </span>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import serv from '../services/HTTPService'

export default {
  components: {
    //
  },
  data: () => ({
    isNotLoaded: true,
    absolute: true,
    opacity: 1,
    overlay: false,
    dense: false,
    fixedHeader: false,
    height: 300,
    products: [],
    actual: [],
    labs: [],
    name_search: '',
    formItems: [],
    formId: 0,
    stateItems: [],
    stateItemsId: 0,
    name: '',
    items: []
  }),
  /* eslint-disable */
  created() {
    const productUrl = serv.getGuestUrl("/products/organicFertilizer")
    this.getProducts(productUrl)
    this.getProductsFormOption()
  },
  methods: {
    getProductsFormOption: function(){
      const productSearchUrl = serv.getGuestUrl('/formOptions/products/search')
    const resValue2 = serv.makeRequest(productSearchUrl, 'GET')

    resValue2.then((result) => {
      //console.log('our select is ', result)
      if (result.status === 200) {
        this.stateItems = result.data.state
        this.formItems = result.data.form
      } else {
        alert(result.message)
        //this.snackbartext = result.message
        //this.snackbar = true
      }
    })
    },
    getProducts: async function (url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Error! status: ${response.status}`);
        }
        const resp = await response.json();
        this.isNotLoaded = false
        this.products = resp.data.products
        this.actual = resp.data.actual
        this.labs = resp.data.labs
      } catch (err) {
        console.log(err);
      }
    },
    makeSearch: function(){
      const sendData = {}

      //if (this.name_search !== undefined) sendData.name = this.name_search
      let searchCount = 0

      if (this.name_search !== '') {
        sendData.name = this.name_search
        searchCount++
      }
      if (
        this.formId.title !== undefined &&
        this.formId.title !== null
      ) {
        sendData.form = this.formId.id
        searchCount++
      }
      if (
        this.stateItemsId.title !== undefined &&
        this.stateItemsId.title !== null
      ) {
        sendData.state = this.stateItemsId.id
        searchCount++
      }
      if (searchCount === 0) {
        this.snackbartext = 'No serch data!'
        this.snackbar = true

        return
      }
      //console.log(sendData);
      this.isNotLoaded = true
      const productUrl = serv.getGuestUrl('/products/search')
      const resValue = serv.makeRequest(productUrl, 'GET', sendData)

      resValue.then((result) => {
        this.isNotLoaded = false
        //this.searchResultStatus = true
        if (result.status === 200) {
          //console.log(result.data.dealers)
          this.products = result.data.products
          this.actual = result.data.actual
          this.labs = result.data.labs
        }
      })
    
    }
  },
};
</script>

<style scoped>
.about{
    border-left: 4px solid #4CAF50;
  }
.item-card {
  min-height: 370px !important;
  margin-bottom: 14px !important;
  border: 1px solid #ccc;
}
.t-table {
  font-size: 12px !important;
}
.bold {
  font-weight: bold !important;
}
.cd-footer {
  font-size: 12px !important;
}
.t-prod {
  margin-bottom: 0px !important;
}

.t-remarks {
  font-size: 11px !important;
  font-style: italic !important;
}
.t-yellow {
  color: yellow;
}

.gen-guide2 {
  color: #4caf50 !important;
  font-size: 16px;
  font-weight: bold;
}

tr {
  width: 200px !important;
}

td,
th {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  min-width: 200px !important;
}

tbody {
  max-height: 200px !important;
}
.cereal {
  height: 34px;
  background-color: #4caf50;
  color: white;
}
.liquid {
  float: right !important;
}
.fertilizer-div {
  background-color: white !important;
}
.fer-heading {
  height: 40px;
  border-left: 2px solid #4caf50;
  padding-left: 20px;
}

.card-div {
  padding-top: 40px;
}
.card-div2 {
  background-color: #4caf50;
  height: 40px;
  border-radius: 80px !important;
  color: white;
  font-size: 14px;
  width: 90%;
  text-align: center;
  margin-top: 28px;
  margin-left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 15px;
}
.card3-div-h1 {
  color: #4caf50;
  text-align: center;
  padding-top: 25px;
}
.zen-text {
  font-size: 16px;
  font-weight: 300px;
  margin-bottom: 0px !important;
}
.crescent {
  font-size: 13px;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.icon-color {
  color: #4caf50 !important;
}

.nutrient-data {
  padding: 20px !important;
  overflow: scroll;
  max-height: 200px !important;
}
</style>