<template>
  <div class="fluid">
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-carousel cycle interval="4000" :show-arrows="false" style="height:100%">
          <v-carousel-item
            class="bg-img1 "
          >
            <v-row class="pt-3">
              <v-col 
                md="3"
                class="d-sm-none d-md-flex"
              >
              </v-col>

              <v-col 
                cols="12"
                md="6"
                class="col-div-remove"
              >
                <v-responsive>
                  <div class="text-white lighten-1 py-5 div-remove rounded-pill">
                    <h2 class="text-white lighten-1 text-center text-display mt-4">Are you a producer or distributor of fertilizer products?</h2>
                    <p class="text-black lighten-1 text-black mt-3 p-text text-center pl-1 pr-1 ">The National Fertilizer Management Platform have 
                      all you need to source, manage and sell your products in line with the new government regulation on fertilizer.
                    </p> 
                    <div class="d-flex justify-content-center">
                      <v-btn
                        class="mr-4 submit p-4"
                        to="/signup"
                      >
                        Get Started
                      </v-btn></div></div>
                </v-responsive>
              </v-col>
              <v-col 
                cols="12"
                md="3"
              >
              </v-col>
            </v-row>
          </v-carousel-item>
          <v-carousel-item
            class="bg-img2"
          >
            <v-row class="pt-3">
              <v-col 
                md="3"
                class="d-sm-none d-md-flex"
              >
              </v-col>

              <v-col 
                cols="12"
                md="6"
                class="col-div-remove"
              >
                <v-responsive>
                  <div class="text-white lighten-1 py-5 div-remove rounded-pill">
                    <h2 class="text-white lighten-1 text-center text-display mt-4">You have a Formula/Product for the Nigerian Market</h2>
                    <p class=" text-white lighten-1 mt-3 p-text text-center pl-2 pr-2">If you have a product for the growing number of farmers in Nigeria. You will be required to create an account.</p> 
                    <div class="d-flex justify-content-center">
                      <v-btn
                        class="mr-4 submit p-4"
                        to="/signup"
                      >
                        Get Started
                      </v-btn></div></div>
                </v-responsive>
              </v-col>
              <v-col 
                cols="12"
                md="3"
              >
              </v-col>
            </v-row>
          
          </v-carousel-item>

          <v-carousel-item
            class="bg-img3"
          >
            <v-row class="pt-3">
              <v-col 
                md="3"
                class="d-sm-none d-md-flex"
              >
              </v-col>

              <v-col 
                cols="12"
                md="6"
                class="col-div-remove"
              >
                <v-responsive>
                  <div class="text-white lighten-1 py-5 div-remove rounded-pill">
                    <h2 class="text-white lighten-1 text-center text-display mt-4">You want to become a supplier or distributor of fertilizer products</h2>
                    <p class="mt-3 p-text text-center pl-2 pr-2">What we need from you? Your company details, contact details. The usual.
                      For details read through our guidelines.
                    </p> 
                    <div class="d-flex justify-content-center">
                      <v-btn
                        class="mr-4 submit p-4"
                        to="/signup"
                      >
                        Get Guidelines
                      </v-btn></div></div>
                </v-responsive>

              </v-col>
              <v-col 
                cols="12"
                md="3"
              >
              </v-col>
            </v-row>

          </v-carousel-item>
          <v-carousel-item
            class="bg-img4"
          >
            <v-row class="pt-3">
              <v-col 
                md="3"
                class="d-sm-none d-md-flex"
              >
              </v-col>

              <v-col 
                cols="12"
                md="6"
                class="col-div-remove"
              >
                <v-responsive>
                  <div class="text-white lighten-1 py-5 div-remove rounded-pill">
                    <h2 class="text-white lighten-1 text-center text-display mt-4 ">Nigerians Farmers Platform</h2>
                    <p class=" text-white lighten-1 mt-3 p-text text-center pl-2 pr-2">If you have a product for the growing number of farmer in Nigeria. You will be required to create an account.</p> 
                    <div class="d-flex justify-content-center">
                      <v-btn
                        class="mr-4 submit p-4"
                        to="/signup"
                      >
                        Get Started
                      </v-btn></div></div>
                </v-responsive>
              </v-col>
              <v-col 
                cols="12"
                md="3"
              >
              </v-col>
            </v-row>
          
          </v-carousel-item>

          <v-carousel-item
            class="bg-img5"
          >
            <v-row class="pt-3">
              <v-col 
                md="3"
                class="d-sm-none d-md-flex"
              >
              </v-col>

              <v-col 
                cols="12"
                md="6"
                class="col-div-remove"
              >
                <v-responsive>
                  <div class="text-white lighten-1 py-5 div-remove rounded-pill">
                    <h2 class="text-white lighten-1 text-center text-display mt-4">Import and Export of Products</h2>
                    <p class=" text-white lighten-1 mt-3 p-text text-center pl-2 pr-2">If you have a product for the growing number of farmer in Nigeria. You will be required to create an account.</p> 
                    <div class="d-flex justify-content-center">
                      <v-btn
                        class="mr-4 submit p-4"
                        to="/signup"
                      >
                        Get Started
                      </v-btn></div></div>
                </v-responsive>
              </v-col>
              <v-col 
                cols="12"
                md="3"
              >
              </v-col>
            </v-row>
          
          </v-carousel-item>

          <v-carousel-item
            class="bg-img6"
          >
            <v-row class="pt-3">
              <v-col 
                md="3"
                class="d-sm-none d-md-flex"
              >
              </v-col>

              <v-col 
                cols="12"
                md="6"
                class="col-div-remove"
              >
                <v-responsive>
                  <div class="text-white lighten-1 py-5 div-remove rounded-pill">
                    <h2 class="text-green lighten-1 text-center text-display mt-4">Dealers Listings</h2>
                    <p class=" text-green lighten-1 mt-3 p-text text-center pl-2 pr-2">
                      There are scammers everywhere and do not get caught up and pay dearly!
                      Know who is who on this platform and industry. </p> 
                    <div class="d-flex justify-content-center">
                      <v-btn
                        class="mr-4 submit p-4"
                        to="/dealerslisting"
                      >
                        See more
                      </v-btn></div></div>
                </v-responsive>
              </v-col>
              <v-col 
                cols="12"
                md="3"
              >
              </v-col>
            </v-row>
          
          </v-carousel-item>
  
        </v-carousel>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  components: {
    //Carousel1,
    //Toolbar2
  }
}
</script>

<style scoped>
.text-outline-green{
  text-shadow: 0.05em 0 green, 0 0.05em green, -0.05em 0 green, 0 -0.05em green !important;
}
.text-outline-black{
  text-shadow: 0.05em 0 black, 0 0.05em black, -0.05em 0 black, 0 -0.05em black !important;
}
.bg-img1{
  background: url('/images/sliders/product2-img.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 530px;
  color: white !important;
  padding: 13px;
  overflow: hidden;
}
.bg-img2{
  background: url('/images/sliders/product-img.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 530px;
  color: white !important;
  padding: 13px;
  overflow: hidden;

}
.bg-img3{
  background: url('/images/sliders/production-img.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 530px;
  color: white !important;
  padding: 13px;
  overflow: hidden;
}
.bg-img4{
  background: url('/images/sliders/plant-img.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 530px;
  color: white !important;
  padding: 13px;
  overflow: hidden;
}
.bg-img5{
  background: url('/images/sliders/import-img.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 530px;
  color: white !important;
  padding: 13px;
  overflow: hidden;
}
.bg-img6{
  background: url('/images/sliders/dealer-img.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 530px;
  color: white !important;
  padding: 13px;
  overflow: hidden;
}
div.text-display{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.text-display{
  font-weight: bold;
  font-size: 32px;
  text-align: left;
}
.p-text{
  font-size: 18px !important;
  text-align: justify;
}
.submit{
  background-color: #4CAF50 !important;
  color: white;
  border-radius: 0px !important;
}

.div-remove{
  /*background-color: rgba(43, 44, 43, 0.753); */
  background-color: rgba(62, 121, 62, 0.2); 
}

@media only screen and (max-width: 600px) {
  .bg-img1, .bg-img2, .bg-img3, .bg-img4, .bg-img5{
    padding-top: 0px !important;
  }
  .div-remove{
    padding-top: 15px !important;
  }
  .col-div-remove{
    margin-top: -40px !important;
  }
}
</style>