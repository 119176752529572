<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list dense nav>
        <v-subheader class="text-uppercase primary--text font-weight-bold" height="100%">Menu</v-subheader>
        <v-divider></v-divider>
        <v-list-item v-for="(item, index) in menu" :key="index" class="rounded-0" :to="item.link">
          <v-list-item-content class="rounded-0">
            <v-list-item-title class="rounded-0">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-btn
      class="d-md-none drawer-button"
      rounded
      @click="drawer = !drawer"
    >
      <v-icon right>mdi-menu</v-icon>
    </v-btn>

    <v-app-bar app flat height="70" class="primary--text border-bottom">
      <v-sheet class="d-flex flex-column flex-grow-1 fill-height">
        <div class="py-1 px-0 d-flex flex-grow-1 align-center sheet-nav">
          <router-link to="/" class="d-flex align-center text-decoration-none mr-2">
            <img
              src="/images/logo.fw.png"
              alt=""
              large
              height="28"
              width="100%"
            >
          </router-link>

          <v-spacer></v-spacer>

          <div class="d-none d-md-block">
            <v-btn
              v-for="(item, index) in menu"
              :key="index"
              :to="item.link"
              large
              text
              color="primary"
              class="btn-nav rounded-0"
            >
              {{ item.title }}
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text  
                  class="btn-nav d-nav-btn rounded-0"
                  v-bind="attrs"
                  v-on="on"
                >
                  Signup/SignIn
                  <v-icon right>
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <v-btn
                    :to="item.link"
                    large
                    text
                    color="primary"
                    class="btn-nav rounded-0"
                  >
                    {{ item.title }}
                  </v-btn>
                  <!-- <v-list-item-title>{{ item.title }}</v-list-item-title> -->
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-sheet>
    </v-app-bar>
  </div>
</template>

<script>
import { menu2 as menu } from './menus.js'

export default {
  data() {
    return {
      drawer: null,
      menu,
      colors: 'deep-purple accent-4',
      items: [
        { 
          title: 'Sign Up',
          link: '/signup' 
        },
        { 
          title: 'Sign In',
          link: '/signin'
        }
      ]
    }
  }
}
</script>

<style>
/* div .btn-nav{
  padding: 30px 17px !important;
  color: #2E7D32 !important;
} */
.drawer-button {
  position: fixed;
  top: 102px;
  left: -22px;
  z-index: 6;
}

a {
  font-size: 12px !important;
  font-weight: 300 !important;
}
.create, .droptext{
  font-size: 12px !important;
  font-weight: 300 !important;
}
.btn-menu{
  font-size:14px !important;
  font-weight: 250;
}

.d-nav-btn{
  color: var(--v-primary-base) !important;
  caret-color: var(--v-primary-base) !important;
  font-size: 12px !important;
  font-weight: normal;
}
</style>