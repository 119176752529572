<template>
  <v-sheet color="">
    <Toolbar />
    <Farmers />
    <FooterPane />
  </v-sheet>
</template>

<script>
import Toolbar from '@/nfmp_components/domain_components/Toolbar.vue'
import Farmers from '@/nfmp_components/Farmers.vue'
import FooterPane from '@/nfmp_components/domain_components/Footer.vue'

export default {
  components: {
    Farmers,
    Toolbar,
    FooterPane
  }
}
</script>
