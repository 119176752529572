<template>
  <v-card flat>
    <v-card-text>
      <v-form ref="corperativeform" v-model="valid" fast-fail>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="first_name"
                label="Contact First Name *"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="surname"
                label="Surname *"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                :counter="11"
                label=" Phone Number *"
                maxlength="11"
                required
              ></v-text-field>
            </v-col>
            <v-col
              class="d-flex"
              cols="12"
              sm="12"
              md="3"
            >
              <v-select
                v-model="gender_model"
                :items="gender_arr"
                label="Gender"
                :rules="[(v) => !!v || 'Item is required']"
              ></v-select>
            </v-col>

          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-select
                v-model="state_model"
                return-object
                item-text="title"
                item-value="id"
                :items="state_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Residence State *"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-select
                v-model="lga_model"
                return-object
                item-text="title"
                item-value="id"
                :items="lga_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Residence LGA *"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="ward"
                label="Ward *"
                :rules="namesRules"
                hint="Must be at least 4 character long"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="home_address"
                :rules="addressRules"
                label="Home Address *"
                clearable
              ></v-text-field>
            </v-col>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-select
                  v-model="meansOfId_model"
                  return-object
                  item-text="title"
                  item-value="id"
                  :items="meansOfId_arr"
                  :rules="[(v) => !!v || 'Item is required']"
                  label="Means of Identification *"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-text-field
                  v-model="IDNumber"
                  :rules="[(v) => !!v || 'Item is required']"
                  label="ID Number *"
                  hint="ID Number"
                  required
                ></v-text-field>
              </v-col>
            
              <v-col cols="12" sm="12" md="4">
                <v-select
                  v-model="corperative_model"
                  return-object
                  item-text="title"
                  item-value="id"
                  :items="corperative_arr"
                  label="Corperative"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="8">
                <v-text-field
                  v-model="farm_location"
                  :rules="addressRules"
                  label="Farm location/address *"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-text-field
                  v-model="farm_size"
                  :rules="phoneRules"
                  :counter="11"
                  label="Farm Size *"
                  maxlength="11"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" class="">
                
                <h2 class="text-h6 mb-2">
                  Select the ones you are producing 
                </h2>
                <v-chip-group
                  v-model="selections"
                  center-active
                  mandatory
                  multiple
                  column
                  active-class="green--text text--accent-5 "
                >
                  <v-chip
                    v-for="item in products"
                    :key="item.id"
                    :value="item.id"
                    filter
                    outlined
                  >
                    {{ item.title }}
                  </v-chip>
                </v-chip-group>
                 
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col sm="5">
                <v-divider class="my-0"></v-divider>
              </v-col>
              <v-col cols="12" sm="2">
                <div class="my-0 caption font-weight-black text-center">Group Lead</div>
              </v-col>
              <v-col cols="12" sm="5">
                <v-divider class="my-0"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  v-model="lead_model"
                  :items="lead_arr"
                  label="Lead Status"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="lead_phone"
                  :rules="phoneRules"
                  :counter="11"
                  label="Lead Phone Number *"
                  maxlength="11"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="lead_first_name"
                  label="Lead First Name *"
                  :rules="namesRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="lead_surname"
                  label="Lead Surname *"
                  :rules="namesRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" sm="12" md="6" class="text-center">
                <v-btn
                  rounded
                  block
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  large
                  @click="loader = 'loading'"
                >
                  Submit 
                </v-btn>
              </v-col>
            </v-row>
          </v-row></v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import serv from '../../services/HTTPService'

export default {
  components: {
    //
  },
  data: () => ({
    isNotLoaded: true,
    absolute: true,
    opacity: 1,
    overlay: false,
    dense: false,
    fixedHeader: false,
    height: 300,
    loading: false,
    
    gender_arr: ['Female', 'Male'],
    lead_model:'',
    lead_arr: ['Village head','Value chain leader'],
    lead_phone:'',
    lead_first_name:'',
    lead_surname:'',
    gender_model:'',
    home_address: '',
    ward: '',
    corperativeType_model: '',
    corperativeType_arr: [],
    state_arr: [],
    state_model: '',
    lga_arr: [],
    lga_model: '',
    company_address: '',
    first_name: '',
    surname: '',
    phone: '',
    products:'',
    product_arr: [],
    productType_model:'',
    productType_arr: [],
    selections: [],
    meansOfId_model:'',
    meansOfId_arr:'',
    IDNumber:'',
    corperative_model :'',
    corperative_arr:[],
    farm_size:'',
    farm_location:'',
    
    valid: '',

    formItems: [],
    formId: 0,
    stateItems: [],
    stateItemsId: 0,
    
    items: [],

    corperativeNameRules: [
      (v) => !!v || 'Corperative name is required',
      (v) =>
        (v && v.length >= 4) ||
        'Corperative name must be greater than 4 characters'
    ],
    namesRules: [
      (v) => !!v || 'Name is required',
      (v) =>
        (v && v.length >= 2) ||
        'Mame must be greater than 2 characters'
    ],
    phoneRules: [
      (v) => !!v || 'Phone Number is required',
      (v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
      (v) => (v && v.length <= 11) || 'Phone Number must be 11 figures'
    ],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    addressRules: [
      (v) => !!v || 'Name is required',
      (v) =>
        (v && v.length >= 15) ||
        'Mame must be greater than 15 characters'
    ]
  }),
  /* eslint-disable */
  created() {
    this.getFormOption()
  },
  methods: {
    getFormOption: function(){
      const formUrl = serv.getGuestUrl('/formOptions/farmers/corperatives')
      const resValue2 = serv.makeRequest(formUrl, 'GET')

    resValue2.then((result) => {
        //console.log('our select is ', result.data.gender)
      if (result.status === 200) {
        this.corperativeType_arr = result.data.corperativeType;
        this.state_arr = result.data.state;
        this.lga_arr = result.data.lga;
        this.products = result.data.products
        this.productType_arr = result.data.productType
        this.corperative_arr = result.data.corperativeType
        this.gender_arr = result.data.gender
        this.meansOfId_arr = result.data.means_of_identification

        const selected = []
        this.products = result.data.products
        /*this.products.forEach((item) => {
          if (item.status > 0) {
            selected.push(item.id)
          }
        })
        this.selections = selected*/
      } else {
        alert(result.message)
        //this.snackbartext = result.message
        //this.snackbar = true
      }
    })
    },
    getProducts: async function (url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Error! status: ${response.status}`);
        }
        const resp = await response.json();
        this.isNotLoaded = false
        this.products = resp.data.products
        this.actual = resp.data.actual
        this.labs = resp.data.labs
      } catch (err) {
        console.log(err);
      }
    },
    makeSearch: function(){
      const sendData = {}

      //if (this.name_search !== undefined) sendData.name = this.name_search
      let searchCount = 0

      if (this.name_search !== '') {
        sendData.name = this.name_search
        searchCount++
      }
      if (
        this.formId.title !== undefined &&
        this.formId.title !== null
      ) {
        sendData.form = this.formId.id
        searchCount++
      }
      if (
        this.stateItemsId.title !== undefined &&
        this.stateItemsId.title !== null
      ) {
        sendData.state = this.stateItemsId.id
        searchCount++
      }
      if (searchCount === 0) {
        this.snackbartext = 'No serch data!'
        this.snackbar = true

        return
      }
      //console.log(sendData);
      this.isNotLoaded = true
      const productUrl = serv.getGuestUrl('/products/search')
      const resValue = serv.makeRequest(productUrl, 'GET', sendData)

      resValue.then((result) => {
        this.isNotLoaded = false
        //this.searchResultStatus = true
        if (result.status === 200) {
          //console.log(result.data.dealers)
          this.products = result.data.products
          this.actual = result.data.actual
          this.labs = result.data.labs
        }
      })
    
    }
  },
};
</script>

<style>
.bg-div{
  background-color: #4CAF50 !important;
  color: white;
}
.b-start{
    border: 2px solid white !important;
    background-color: #4CAF50 !important;
    color: white !important;
}
h5{
    font-size: 30px;
}
p{
    font-size: 20px !important;
    font-weight: 300 !important;
}
</style>