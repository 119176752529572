<template>
  <Footer4 />
</template>

<script>
import Footer4 from '@/components/ui/landing/footer/Footer4.vue'

export default {
  components: {
    Footer4
  }
}
</script>
