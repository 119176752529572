<template>
  <div class="div-bg">
    <v-container>
      <v-sheet>
        <div class="branch p-3">
          <h3>
            Reset Password
          </h3>
        </div>
        <div class="red lighten-3 white--text mt-4 p-3 shadow-card">
          Make this entry only if you have not set you password before
        </div>
        <v-container>
          <v-form class="mt-2">
            <v-text-field 
              v-model="companyName"
              :counter="30"
              :rules="companyNameRules"
              label="Company Name" 
              hint="It must not be less than 30"
              required
            ></v-text-field>            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="tin"
                  :counter="30"
                  :rules="tinRules"
                  label="TIN *"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="emailBus" :rules="emailBusRules" label="Business Email *" required></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="emailCon" :rules="emailContactRules" label="Contact Email *" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Password"
                  hint="At least 8 characters"
                  counter
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
            </v-row>
            <div class="d-flex justify-content-center">
              <v-btn submit class="primary lighten-3 p-4 mt-2 white--text rounded-0" @click="Submit"> Submit </v-btn>
            </div>
          </v-form>
        </v-container>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      show1: false,
      valid: true,
      password: '',
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => ('The email and password you entered don\'t match')
      },
      companyName: '',
      companyNameRules: [
        (v) => !!v || 'Company Name is required',
        (v) => (v && v.length <= 30) || 'Name must be less than 30 characters'
      ],
      emailBus: '',
      emailBusRules: [
        (v) => !!v || 'Business E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      emailCon: '',
      emailContactRules: [
        (v) => !!v || 'Company E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      cac: '',
      cacRules: [
        (v) => !!v || 'CAC Number is required',
        (v) => (v && v.length <= 11) || 'Name must be less than 11 characters'
      ],
      tin: '',
      tinRules: [
        (v) => !!v || 'TIN Number is required',
        (v) => (v && v.length <= 30) || 'Name must be less than 30 characters'
      ]
    }
  },
  methods: {
    Submit: function () {
      alert('This is a Test version, we will be back LIVE')
    }
  }
}
</script>

<style lang="scss" scoped>
.div-bg{
  background-color: white !important;
}
.branch{
  border-left: 4px solid #4CAF50;
}
</style>