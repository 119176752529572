<template>
  <v-container>
    <div class="text-center pt-3">
      <v-icon class="primary--text icon mb-5">
        mdi-check-circle
      </v-icon>
      <p class="sub-h">Registration Confirmation</p>
    </div>
    <div class="d-flex justify-content-center">
      <v-sheet class="p-3 rounded-0" text color="transparent" max-width="745px">
        <p class="p-data">Your data has been submitted and account creation process has began!
          We will send you a notification email on how to finalize the account creation.</p>
      </v-sheet>
    </div>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>
.icon{
    font-size:180px !important;
}
.sub-h{
    font-size: 32px !important;
}
.p-data{
    font-size: 19px !important;
}
</style>