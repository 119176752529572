<template>
  <div>
    <Toolbar />
    <FISS_Department />
    <FooterPane />
  </div>
</template>

<script>
import Toolbar from '@/nfmp_components/domain_components/Toolbar.vue'
import FISS_Department from '@/nfmp_components/FISS_Department.vue'
import FooterPane from '@/nfmp_components/domain_components/Footer.vue'

export default {
  components: {
    Toolbar,
    FISS_Department,
    FooterPane

  }
}
</script>

<style scoped>
*{
  background-color: white !important;
}
</style>