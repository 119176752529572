<template>
  <v-sheet color="">
    <Toolbar />
    <SignUp />
    <FooterPane />
  </v-sheet>
</template>

<script>
import Toolbar from '@/nfmp_components/domain_components/Toolbar.vue'
import SignUp from '@/nfmp_components/SignUp.vue'
import FooterPane from '@/nfmp_components/domain_components/Footer.vue'

export default {
  components: {
    SignUp,
    Toolbar,
    FooterPane
  }
}
</script>
