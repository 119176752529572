<template>
  <div>
    <Toolbar />
    <HelpCenter />
    <FooterPane />
  </div>
</template>

<script>
/*import Toolbar4 from '@/components/ui/landing/toolbar/Toolbar4.vue'
import HelpCenter from '@/components/ui/helpcenter/HelpCenter.vue'
import Footer4Entry from '@/components/ui/landing/footer/Footer4Entry.vue'*/

import Toolbar from '@/nfmp_components/domain_components/Toolbar.vue'
import HelpCenter from '@/nfmp_components/HelpCenter.vue'
import FooterPane from '@/nfmp_components/domain_components/Footer.vue'

export default {
  components: {
    Toolbar,
    HelpCenter,
    FooterPane

  }
}
</script>