<template>
  <div>
    <ContactUs />
    <Toolbar />
    <FooterPane />
  </div>
</template>

<script>
import Toolbar from '@/nfmp_components/domain_components/Toolbar.vue'
import ContactUs from '@/nfmp_components/ContactUs.vue'
import FooterPane from '@/nfmp_components/domain_components/Footer.vue'

export default {
  components: {
    ContactUs, 
    Toolbar,
    FooterPane
    // ForgotPassword
  }
}
</script>