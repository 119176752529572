<template>
  <v-sheet color="">
    <Toolbar />
    <UpdateAccount />
    <FooterPane />
  </v-sheet>
</template>

<script>
//import UpdateAccount from '@/components/ui/landing/signup/UpdateAccount.vue'
import Toolbar from '@/nfmp_components/domain_components/Toolbar.vue'
import UpdateAccount from '@/nfmp_components/UpdateAccount.vue'
import FooterPane from '@/nfmp_components/domain_components/Footer.vue'

export default {
  components: {
    UpdateAccount,
    Toolbar,
    FooterPane
  }
}
</script>