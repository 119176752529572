<template>
  <div class="">
    <v-sheet >
      <v-container class=" py-lg-8">
        <v-row>
          <h3 class="text-h4 text-center mt-2">Complementary Platforms </h3>
          <div class="d-flex justify-content-center">
            <div style="width: 150px; height: 3px" class="mb-5 mt-1 primary  lighten-2" />

          </div>
          <v-col
            cols="12"
            sm="6"
            lg="4"
          >
            <v-responsive class="mx-auto p-3">
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  lg="2"
                >
                  <div class="">
                    <v-icon class="text-h4" color="primary">mdi-nature-people</v-icon>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  lg="10"
                >
                  <div class="text-h6 title">Farmer's Platform</div>
                  <div class="font-weight-regular mt-2 descrip">A national outreach programme for farmers. Where farmers are registered, validated and get fertilizer products.</div>
                  <v-btn x-medium class=" mt-2 mr- w-sm-auto rounded-0 know-btn" to="/#">See More</v-btn>
              
                </v-col>
              </v-row>
            </v-responsive>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="4"
          >
            <v-responsive class="mx-auto p-3">

              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  lg="2"
                >
                  <div class="">
                    <v-icon class="text-h4" color="primary">mdi-import </v-icon>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  lg="10"
                >
                  <div class="text-h6 title">Import/Export Hub</div>
                  <div class="font-weight-regular mt-2 descrip">A single window for importation and exportation of fertilizer raw materials and products.</div>
                  <v-btn x-medium class=" mt-2 w-sm-auto rounded-0 know-btn" to="/#">See More</v-btn>
                </v-col>
              </v-row>
            </v-responsive>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="4"
          >
            <v-responsive class="mx-auto p-3">

              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  lg="2"
                >
                  <div class="">
                    <v-icon class="text-h4" color="primary">mdi-list-status</v-icon>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  lg="10"
                >
                  <div class="text-h6 title">Dealers Listing</div>
                  <div class="font-weight-regular mt-2 descrip"> If you want to which company is registered on this platfrom and the line of business
                  </div>
                  <v-btn x-medium class=" mt-2 w-sm-auto rounded-0 know-btn" to="/dealerslisting">See More</v-btn>
                </v-col>
              </v-row>
            </v-responsive>
          </v-col>
        </v-row>
                
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
export default {
  data() {
    return {
      features: [{
        icon: 'mdi-silo',
        title: 'Our Bra',
        description: 'FISS department want to reach out all part of the country to serve you better.'
      }, {
        icon: 'mdi-alert-octagon',
        title: 'Whistle Blowing',
        description: 'Report fraud and inappropriate activities in a safe, confidential and secure way.'
      }, {
        icon: 'mdi-help-circle',
        title: 'Help Center',
        description: 'Do you have issues with any of our services. Send us a ticket, call or email us.'
      }
      ]
    }
  }
}
</script>

<style scoped>
*{
  background-color: white !important;
}
.title{
  color: #2E7D32 !important;
}
.descrip{
  color: #4CAF50 !important;
  font-size: 13.5px !important;
}
.know-btn{
  background-color: #4CAF50 !important;
  color: white;
}
.hover-card:hover{
box-shadow: 2px 3px 2px 3px #bdc0bd;
}
</style>
