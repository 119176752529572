<template>
  <div>
    <Toolbar />
    <WhistleBlowing />
    <FooterPane />
    
  </div>
</template>

<script>
/*import Toolbar4 from '@/components/ui/landing/toolbar/Toolbar4.vue'
import WhistleBlowing from '@/components/ui/whistleblowing/WhistleBlowing.vue'
import Footer4Entry from '@/components/ui/landing/footer/Footer4Entry.vue'*/

import Toolbar from '@/nfmp_components/domain_components/Toolbar.vue'
import WhistleBlowing from '@/nfmp_components/WhistleBlowing.vue'
import FooterPane from '@/nfmp_components/domain_components/Footer.vue'

export default {
  components: {
    Toolbar,
    WhistleBlowing,
    FooterPane
  }
}
</script>