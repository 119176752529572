<template>
  <div>
    <Toolbar4 />
    <Branches />
    <Footer4Entry />

  </div>
</template>

<script>
import Toolbar4 from '@/components/ui/landing/toolbar/Toolbar4.vue'
import Branches from '@/components/ui/landing/homeGuide/Branches.vue'
import Footer4Entry from '@/components/ui/landing/footer/Footer4Entry.vue'

export default {
  components: {
    Toolbar4,
    Branches,
    Footer4Entry

  }
}
</script>