<template>
  <div>
    <ForgotPassword />
  </div>
</template>

<script>
import ForgotPassword from '@/components/ui/landing/signin/ForgotPassword.vue'
export default {
  components: {
    ForgotPassword
  }
}
</script>