<template>
  <v-container>
    <div class="about p-2">
      <h3>Fertilizer Organic Products
      </h3>
    </div>
    <v-expansion-panels
      v-model="panel"
      :readonly="readonly"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="gen-guide">General Guidelines</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-sheet class="secondary py-2 mb-3">
            <p class=" secondary primary--text pl-4 gen-guide2">
              All companies or individuals wishing to undertake any business venture in fertilizer sector (manufacturing / blending / importation and / or distribution) must satisfy the following conditions
            </p>
          </v-sheet>
          <ol>
            <li>Register with the Farm Inputs Support Services Department of the Federal Ministry of Agriculture and Rural Development through the following processes:</li>
            <ol type="i" class="pb-2">
              <li class="mb-2">Create an online account (Sign up)</li>
              <li class="mb-2">Wait for the account to be approved</li>
              <li>Upon approval, login into the account to proceed with the application proper by filling the forms as applicable</li>
            </ol>
            <li>Upon the completion of the two conditions above, Officials of FISS Department will undertake a visit to the business premises of the company for inspection and assessment of the facility in the following areas:</li>
            <ol type="i">
              <li class="mb-2">Confirming the physical address of your facility</li>
              <li class="mb-2">Physical inspection of the premises for security and safety considerations</li>
              <li>Verification of the information declared by the applicant in the application form</li>
            </ol>
          </ol>
            
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="gen-guide">Guidelines and processes for importation of fertilizer and fertilizer raw materials</v-expansion-panel-header>
        <v-expansion-panel-content class="ml-4">
          <p class="text-danger text-justify">Please note that the importation of Urea 46%N and all NPK fertilizers are prohibited. You may refer to the Fiscal Policy Circular No. F.17417/III/186 of 17th January 2020</p>
          <p>Any company or individuals wishing to import fertilizer and/or fertilizer blending materials must register with the Farm Inputs Support Services Department on this platform</p>
        
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="gen-guide">Guidelines for Establishment of Fertilizer Manufacturing and Blending Plant</v-expansion-panel-header>
        <v-expansion-panel-content class="ml-4 text-justify">
          <ol>
            <li class="mb-2">
              Apply to the Department for Expression of Interest using the online registration portal
            </li>
            <li class="mb-2">Wait for approval of application before commencement of the installation of the machines and other necessary facilities</li>
            <li class="mb-2">Upon the completion of the installation of the necessary facilities, you are expected to formally invite the Department for official inspection before commencement of full blending operations. It is at this point that Officials of this Department will undertake a visit to the premises of the blending plant for inspection and assessment of the facility in the following areas:</li>
            <ol type="i">
              <li class="mb-2">Confirming the physical address of your facility;</li>
              <li class="mb-2">Physical inspection of the premises for security and safety considerations;</li>
              <li class="mb-2">Collection of official samples of the fertilizer blend(s) from the production line which will be analyzed in the laboratory to ensure it meets the minimum standards in terms of physical properties and nutrients contents.</li>
            </ol>
          </ol>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="gen-guide">Installation Checklist</v-expansion-panel-header>
        <v-expansion-panel-content class="ml-4 text-justify">
          <ol>
            <li class="mb-2">
              Installation of the following
              <ol type="i" class="mb-1">
                <li class="mb-2">Perimeter fencing (Concrete wall with strong pillars)</li>
                <li class="mb-2">Overhead Concertina wire on the perimeter fence</li>
                <li class="mb-2">Adequate high-power halogen/fluorescent lights on both sides of the factory building and front and back of the building to cover the entire premises</li>
                <li class="mb-2">CCTV cameras at strategic and hidden positions to cover entire premises</li>
                <li class="mb-2">Observatory post/tower for security watch</li>
                <li class="mb-2">Mini Laboratory for analysis of samples of raw materials and production batches</li>
              </ol>
            </li>
            <li class="mb-2">Separate or demarcate production areas from the raw materials holding area and storage areas for finished products</li>
            <li class="mb-2">Provide Visitors/Staff Entry and Exit Register and Tags to track entry and exit of persons in and out of the premises</li>
            <li class="mb-2">Create a data base for your customers (distributors and/or agro-dealers); and open an official register for all individuals visiting the premises as parts of the measures for addressing the security and safety concerns in fertilizer distribution in the country. In this regard, all your intending customers should be made to complete the “Know Your Customer” (KYC) bio-data form before registration as a distributor or dealer with your company.</li>
            <li class="mb-2">Inform this Department as soon as blending operations commence for final verification/assessment and collection of samples of the fertilizer blends for quality analysis in the laboratory</li>
          </ol>
          <div class="p-5">
            <p class="gen-guide2">Please note that you can contact the Department at any time during the installation process for any assistance(s) on the Apply For installation Assistance on the portal. However, your company shall be responsible for the cost of such visits</p>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>

<!--
  <div>
    <v-container class="py-6">
      <div class="guide-heading pt-3 mb-4">
        <h2 class=""> Guidelines</h2> 
      </div>
      <v-expansion-panels
        v-model="panel"
        :readonly="readonly"
        multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="gen-guide">General Guidelines</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-sheet class="secondary py-2 mb-3">
              <p class=" secondary primary--text pl-4 gen-guide2">
                All companies or individuals wishing to undertake any business venture in fertilizer sector (manufacturing / blending / importation and / or distribution) must satisfy the following conditions
              </p>
            </v-sheet>
            <ol>
              <li>Register with the Farm Inputs Support Services Department of the Federal Ministry of Agriculture and Rural Development through the following processes:</li>
              <ol type="i" class="pb-2">
                <li class="mb-2">Create an online account (Sign up)</li>
                <li class="mb-2">Wait for the account to be approved</li>
                <li>Upon approval, login into the account to proceed with the application proper by filling the forms as applicable</li>
              </ol>
              <li>Upon the completion of the two conditions above, Officials of FISS Department will undertake a visit to the business premises of the company for inspection and assessment of the facility in the following areas:</li>
              <ol type="i">
                <li class="mb-2">Confirming the physical address of your facility</li>
                <li class="mb-2">Physical inspection of the premises for security and safety considerations</li>
                <li>Verification of the information declared by the applicant in the application form</li>
              </ol>
            </ol>
            
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="gen-guide">Guidelines and processes for importation of fertilizer and fertilizer raw materials</v-expansion-panel-header>
          <v-expansion-panel-content class="ml-4">
            <p class="text-danger text-justify">Please note that the importation of Urea 46%N and all NPK fertilizers are prohibited. You may refer to the Fiscal Policy Circular No. F.17417/III/186 of 17th January 2020</p>
            <p>Any company or individuals wishing to import fertilizer and/or fertilizer blending materials must register with the Farm Inputs Support Services Department on this platform</p>
        
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="gen-guide">Guidelines for Establishment of Fertilizer Manufacturing and Blending Plant</v-expansion-panel-header>
          <v-expansion-panel-content class="ml-4 text-justify">
            <ol>
              <li class="mb-2">
                Apply to the Department for Expression of Interest using the online registration portal
              </li>
              <li class="mb-2">Wait for approval of application before commencement of the installation of the machines and other necessary facilities</li>
              <li class="mb-2">Upon the completion of the installation of the necessary facilities, you are expected to formally invite the Department for official inspection before commencement of full blending operations. It is at this point that Officials of this Department will undertake a visit to the premises of the blending plant for inspection and assessment of the facility in the following areas:</li>
              <ol type="i">
                <li class="mb-2">Confirming the physical address of your facility;</li>
                <li class="mb-2">Physical inspection of the premises for security and safety considerations;</li>
                <li class="mb-2">Collection of official samples of the fertilizer blend(s) from the production line which will be analyzed in the laboratory to ensure it meets the minimum standards in terms of physical properties and nutrients contents.</li>
              </ol>
            </ol>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="gen-guide">Installation Checklist</v-expansion-panel-header>
          <v-expansion-panel-content class="ml-4 text-justify">
            <ol>
              <li class="mb-2">
                Installation of the following
                <ol type="i" class="mb-1">
                  <li class="mb-2">Perimeter fencing (Concrete wall with strong pillars)</li>
                  <li class="mb-2">Overhead Concertina wire on the perimeter fence</li>
                  <li class="mb-2">Adequate high-power halogen/fluorescent lights on both sides of the factory building and front and back of the building to cover the entire premises</li>
                  <li class="mb-2">CCTV cameras at strategic and hidden positions to cover entire premises</li>
                  <li class="mb-2">Observatory post/tower for security watch</li>
                  <li class="mb-2">Mini Laboratory for analysis of samples of raw materials and production batches</li>
                </ol>
              </li>
              <li class="mb-2">Separate or demarcate production areas from the raw materials holding area and storage areas for finished products</li>
              <li class="mb-2">Provide Visitors/Staff Entry and Exit Register and Tags to track entry and exit of persons in and out of the premises</li>
              <li class="mb-2">Create a data base for your customers (distributors and/or agro-dealers); and open an official register for all individuals visiting the premises as parts of the measures for addressing the security and safety concerns in fertilizer distribution in the country. In this regard, all your intending customers should be made to complete the “Know Your Customer” (KYC) bio-data form before registration as a distributor or dealer with your company.</li>
              <li class="mb-2">Inform this Department as soon as blending operations commence for final verification/assessment and collection of samples of the fertilizer blends for quality analysis in the laboratory</li>
            </ol>
            <div class="p-5">
              <p class="gen-guide2">Please note that you can contact the Department at any time during the installation process for any assistance(s) on the Apply For installation Assistance on the portal. However, your company shall be responsible for the cost of such visits</p>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>-->
</template>

<script>
export default {
  data: () => ({
    panel: [0],
    readonly: false
  })
}
</script>

<style scoped>
.about{
    border-left: 4px solid #4CAF50;
  }
.guide-heading{
  height: 80px;
  border-left: 4px solid #4CAF50;
  padding-left: 20px;
}
*{
  background-color: white !important;
}
.gen-guide{
  color: #4CAF50;
  font-size: 18px;
}
.gen-guide2{
  font-size: 15px !important;
}
</style>
