<template>
  <v-sheet >
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          lg="4"
        >
          <v-responsive class="mx-auto p-3">
            <v-row>
              <v-col
                cols="12"
                sm="6"
                lg="2"
              >
                <div class="">
                  <v-icon class="text-h4" color="primary">mdi-crosshairs-gps </v-icon>
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                lg="10"
              >
                <div class="text-h6 title">Our Branch Offices</div>
                <div class="font-weight-regular mt-2 descrip">FISS department want to reach out all part of the country to serve you better.</div>
                <v-btn x-medium class="mt-2 w-sm-auto rounded-0 know-btn" to="/Branches">See More</v-btn>
              
              </v-col>
            </v-row>
          </v-responsive>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
        >
          <v-responsive class="mx-auto p-3">

            <v-row>
              <v-col
                cols="12"
                sm="6"
                lg="2"
              >
                <div class="">
                  <v-icon class="text-h4" color="primary">mdi-alert-octagon </v-icon>
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                lg="10"
              >
                <div class="text-h6 title">Whistle Blowing</div>
                <div class="font-weight-regular mt-2 descrip">Report fraud and inappropriate activities in a safe, confidential and secure way.</div>
                <v-btn x-medium class=" mt-2 w-sm-auto rounded-0 know-btn" to="/WhistleBlowing">See More</v-btn>
              </v-col>
            </v-row>
          </v-responsive>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
        >
          <v-responsive class="mx-auto p-3">

            <v-row>
              <v-col
                cols="12"
                sm="6"
                lg="2"
              >
                <div class="">
                  <v-icon class="text-h4" color="primary">mdi-help-circle</v-icon>
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                lg="10"
              >
                <div class="text-h6 title">Help Center</div>
                <div class="font-weight-regular mt-2 descrip">Do you have issues with any of our services. Send us a ticket, call or email us.</div>
                <v-btn x-medium class="mt-2 w-sm-auto rounded-0 know-btn" to="/HelpCenter">See More</v-btn>
              </v-col>
            </v-row>
          </v-responsive>
        </v-col>
      </v-row>
                
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      features: [{
        icon: 'mdi-crosshairs-gps',
        title: 'Our Branch Offices',
        description: 'FISS department want to reach out all part of the country to serve you better.'
      }, {
        icon: 'mdi-alert-octagon',
        title: 'Whistle Blowing',
        description: 'Report fraud and inappropriate activities in a safe, confidential and secure way.'
      }, {
        icon: 'mdi-help-circle',
        title: 'Help Center',
        description: 'Do you have issues with any of our services. Send us a ticket, call or email us.'
      }
      ]
    }
  }
}
</script>

<style scoped>
*{
  background-color: #E8F5E9 !important;
}
.title{
  color: #2E7D32 !important;
}
.descrip{
  color: #4CAF50 !important;
  font-size: 13.5px !important;
}
.know-btn{
  background-color: #4CAF50 !important;
  color: white;
}
.hover-card:hover{
box-shadow: 2px 3px 2px 3px #bdc0bd;
}
</style>
