<template>
  <div class="div-bg">
    <v-container>
      <v-sheet>
        <div class="branch p-3">
          <h3>Account Verification
          </h3>
        </div>
        <div class="grey lighten-4 primary--text mt-4 p-3 shadow-card">
          To update your account record we need to verify you have submitted your record before!
        </div>
        <v-container>
          <v-form 
            ref="accountverifyform"
            v-model="valid" 
            class="mt-2"
          >
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="tin"
                  :counter="30"
                  :rules="tinRules"
                  label="TIN *"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="email"
                  type="email"
                  :rules="emailRules"
                  label="Business Email *"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <div class="d-flex justify-content-center">
              <v-btn
                submit
                class="primary p-4 mt-2 white--text rounded-0"
                @click="CheckValidation()"
              > Submit </v-btn>
            </div>
          </v-form>
        </v-container>
      </v-sheet>
      <v-snackbar
        v-model="snackbar"
      >
        {{ snackbartext }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
//import myServices from '../../../assets/services/myservices'
import httpServ from '../services/HTTPService'

export default {
  data: () => ({
    valid: true,
    snackbar: false,
    snackbartext: '',
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    tin: '',
    tinRules: [
      (v) => !!v || 'Tin Number is required',
      (v) => /^\d{8}-\d{4}$/.test(v) || 'TIN must be valid 12345678-0001',
      (v) => (v && v.length <= 13) || 'Tin must be 13 character long'
    ]
    // checkbox: false
  }),

  methods: {
    CheckValidation () {
      if (this.$refs.accountverifyform.validate()) {
        const sendData = {
          business_email: this.email,
          TIN: this.tin
        }

        //const res_value = myServices.verifyAccount(send_data)
        this.signupBtnStatus = true
        this.signupBtnText = 'Submitting...'
        const authenUrl = httpServ.getAuthenUrl('/members/verifyAccount4Update')
        const resValue = httpServ.makeRequest(authenUrl, 'POST', sendData)

        resValue.then( (result) => {
          //console.log('our result is ', result)
          if (result.status === 200) {     
            this.membership_id = result.data.membership_id
            const process_d = {
              data: result.data,
              usertype: 'User'
            }

            httpServ.setSessionItem('userUpdateData', JSON.stringify(process_d))
            this.$router.push({ name: 'update_account' })
          } else {
            this.snackbar = true
            this.snackbartext = result.message
          }
        })

      } else {
        this.snackbar = true
        this.snackbartext = 'Please provide valid information'
      }      
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    }
  }
}
</script>

<style scoped>
.div-bg{
  background-color: white !important;
}
.branch{
  border-left: 4px solid #4CAF50;
}
</style>