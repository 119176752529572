<template>
  <div>
    <v-container class="py-6">
      <div class="guide-heading pt-3 mb-4">
        <h2 class=""> Help Center</h2> 
      </div>
      <v-row class="pb-4 pt-4">
        <v-col 
          cols="12" 
          sm="12"
          md="6"
        >
          <v-card outlined class=" d-flex flex-column p-2shadow-card rounded-0 card-edit">
            <v-card-title class="font-weight-bold grey lighten-4 green-text card-height">
              <h6 class=" grey lighten-4 green--text">
                Registration Renewal
              </h6>
            </v-card-title>
            <v-card-text class="">
              If you have been experiencing problems in upload your documents or difficulties in completing your registration then click on the button below. You cannot renew your record more twice than once.
            </v-card-text>
            <v-card-actions class=" lighten-4 green-text card-height">
              <v-btn class="primary rounded-0" text color="white" to="verify_account">Account Update</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col 
          cols="12" 
          sm="12"
          md="6"
        >
          <v-card outlined class=" d-flex flex-column p-2shadow-card rounded-0 card-edit">
            <v-card-title class="font-weight-bold grey lighten-4 green-text card-height">
              <h6 class=" grey lighten-4 green--text">
                Notification Failure
              </h6>
            </v-card-title>
            <v-card-text class="">
              If you have not received your registration notification email, and you are sure of it has been sent. This is strictly where you created your login password and should only be carried out when asked to do so!
            </v-card-text>
            <v-card-actions class=" lighten-4 green-text card-height">
              <v-btn class="primary rounded-0" text color="white" to="setpassword">Create Account Password</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- <v-col cols="12" sm="4" md="6">
          <Card1 />
        </v-col> -->
        <v-col 
          cols="12" 
          sm="12"
          md="12"
        >
          <v-card outlined class=" d-flex flex-column p-2shadow-card rounded-0 card-edit">
            <v-card-title class="font-weight-bold grey lighten-4 green-text card-height">
              <h6 class=" grey lighten-4 green--text">
                Support
              </h6>
            </v-card-title>
            <v-card-text class="">
              <p>Telephone: +234-90 3382 980, +234-90 3382 980.</p>
              <p>Email: support@nfmp.com.ng</p>
              <p>Whatsapp: +234-90 3382 980</p>
              <p>Twitter: https://twitter.com/NFMP</p>
              <p>Instagram: https://www.instagram.com/NFMP</p>
              <p>Facebook: https://www.facebook.com/NFMP/</p>
              <p>YouTube: https://www.youtube.com/user/nfmp</p>
            </v-card-text>
          </v-card>
        </v-col>
        
      </v-row>
      <ContactHelpCenter />
    </v-container>
  </div>

</template>

<script>
//import Card1 from '@/components/ui/helpcenter/Card1.vue'
//import Card2 from '@/components/ui/helpcenter/Card2.vue'
import ContactHelpCenter from '@/components/ui/helpcenter/ContactHelpCenter.vue'

export default {
  components: {
    //Card1,
    //Card2,
    ContactHelpCenter
  },
  props: {
    title: {
      type: String,
      default: 'Registration Renewal'
    },
    description: {
      type: String,
      default: 'If you have been experiencing problems in upload your documents or difficulties in completing your registration then click on the button below. You cannot renew your record more twice than once.'
    },
    label: {
      type: String,
      default: 'Account Update'
    },
    to: {
      type: String,
      default: 'verify_account'
    }
  }
}
</script>

<style scoped>

.card-edit{
  min-height: 230px !important;
}
.guide-heading{
  height: 80px;
  border-left: 4px solid #4CAF50;
  padding-left: 20px;
}

*{
  background-color: white !important;
}
/* .shadow-card{
  box-shadow: 1px 1px 1px 1px rgb(179, 179, 179) !important;
} */
</style>