<template>
  <div>
    <Toolbar4 />
    <Successful />
    <Footer4Entry />
  </div>
</template>

<script>
import Toolbar4 from '@/components/ui/landing/toolbar/Toolbar4.vue'
import Successful from '@/components/ui/success/Successful.vue'
import Footer4Entry from '@/components/ui/landing/footer/Footer4Entry.vue'

export default {
  components: {
    Toolbar4,
    Successful,
    Footer4Entry

  }
}
</script>