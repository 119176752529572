<template>
  <v-sheet color="">
    <Toolbar />
    <Upload />
    <FooterPane />
  </v-sheet>
</template>

<script>
/*import UploadDocumentEntry from '@/components/ui/landing/signup/UploadDocumentEntry.vue'
export default {
  components: {
    UploadDocumentEntry
  }
}*/
import Toolbar from '@/nfmp_components/domain_components/Toolbar.vue'
import Upload from '@/nfmp_components/UploadDocument.vue'
import FooterPane from '@/nfmp_components/domain_components/Footer.vue'

export default {
  components: {
    Upload,
    Toolbar,
    FooterPane
  }
}
</script>