<template>
  <v-container class="py-6">
   
    <!-- <div style="width: 80px; height: 4px" class="mb-3 secondary mx-auto" /> -->

    <v-card class="pa-3 mx-auto" max-width="500" color="grey lighten-4">
      <v-card-title>
        <v-divider class="my-1"></v-divider>
        <div class="mx-2">Sign In</div>
        <v-divider class="my-1"></v-divider>
      </v-card-title>
      <v-card-text>
        <v-text-field label="Phone Number" outlined></v-text-field>
        <v-text-field label="Password" outlined hide-details></v-text-field>
        <v-checkbox dense label="Remember me"></v-checkbox>
        <v-btn block class="success-btn rounded-0" x-large @click="submitSignin">Sign In</v-btn>
        <div class="text-center mt-2 text-body-2">
          <span>Do no have an account? click</span>        <router-link to="/SignUp"> Create one </router-link> <span> or </span>

          <router-link to="/">Forgot your password?</router-link>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  methods: {
    submitSignin: () => {
      router.push({ name: 'userfeed' })
    }
  }
}
</script>

<style scoped>
.signin{
  padding: 20px;
  background-color: #329332;
  margin-bottom: 20px;
}
.here{
  color: #329332 !important;
  text-decoration: none !important;
}
.signinText{
  text-align: center;
}
.btn-sub{
  border: 2px solid #329332 !important;
  background-color: white !important;
  width: 120px;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  border-radius: 0px !important;
  color: #329332;
  margin-left: 70% !important;
}
.btn-sub:hover{
  background-color: #329332 !important;
  color: white !important;
}
.top-f{
justify-content: center;
color: #4CAF50;
}
.top-n:hover{
  cursor: pointer !important;
}
.na-feti{
  border-left: 4px solid#B3B303 !important;
}
.success-btn{
  background-color: #4CAF50 !important;
  color: white;
}
</style>