<template>
  <v-responsive class="bg-div" max-height="">
    <v-container class="py-4 py-lg-8">
      <div>
        <h5 class="text-center mb-3">Do it all on the National Fertilizer Management Platform(NFMP)</h5>
        <p class="text-center">The one-stop platform that serves all, be it new or established fertilizer vendor, by maximizing your input, marketing channels, and sales together so you can reach your goals faster</p>
        <div class="d-flex justify-content-center">
          <v-btn class="b-start p-3 rounded-5" @click="$router.push('/SignUp')">GET STARTED</v-btn>
        </div>
      </div>
    </v-container>
  </v-responsive>
</template>

<script>
export default {

}
</script>

<style>
.bg-div{
  background-color: #4CAF50 !important;
  color: white;
}
.b-start{
    border: 2px solid white !important;
    background-color: #4CAF50 !important;
    color: white !important;
}
h5{
    font-size: 30px;
}
p{
    font-size: 20px !important;
    font-weight: 300 !important;
}
</style>