<!-- eslint-disable comma-dangle -->
<template>
  <div class="container grey lighten-5">
    <!-- <v-row> -->
    <v-stepper v-model="e13" vertical>
      <div class="createAcct"><h1>Update Account</h1></div>
      <v-stepper-step step="1" :complete="e13 > 1">
        <h4>Company Details</h4>
        <small>Fields marked with <sup>*</sup> are mandatory</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card color="grey lighten-5" class="mb-12">
          <v-form ref="companydetailform" v-model="valid" fast-fail>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="company_name"
                    label="Company Name *"
                    counter="30"
                    :rules="companyNameRules"
                    hint="Must be atleast 5 character long"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="tinName"
                    label="Tin *"
                    counter="13"
                    :rules="tinNameRules"
                    hint="Must be atleast 1 character long"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="mainBusinessLineId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="mainBusItems1"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="Main Line Of Business *"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="4">
                  <v-select
                    v-model="secondBusinessLineId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="secBusItems2"
                    label="Second Line Of Business *"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="4">
                  <v-select
                    v-model="companyOwnershipId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="companyItems"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="Company Ownership type *"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="business_email"
                    :rules="emailRules"
                    label="Business E-mail"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="business_phone"
                    :rules="phoneRules"
                    :counter="11"
                    label="Phone Number *"
                    maxlength="11"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="CAC_registration_number"
                    :counter="11"
                    :rules="cacRules"
                    label="C.A.C. Registration Number *"
                    hint="Must be at least 8 character long"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="date_of_incorporation"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_of_incorporation"
                        label="Date of Incorperation *"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date_of_incorporation" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(date_of_incorporation)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="residenceStateId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="residentItems"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="Residence State(HQ) *"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="company_address"
                    :rules="addressRules"
                    label="Company Address *"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
        <v-btn
          color="primary rounded-0"
          @click="firstStepProceed()"
        >Continue</v-btn>
      </v-stepper-content>

      <v-stepper-step step="2" :complete="e13 > 2">
        <h4>Contact Details</h4>
      </v-stepper-step>

      <v-stepper-content step="2" complete>
        <v-card color="grey lighten-4" class="mb-12">
          <v-form ref="contactdetailform" v-model="valid" fast-fail>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="first_name"
                    label="First name *"
                    :rules="firstNameRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="middle_name"
                    label="Middle name *"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="surname"
                    label="Surname *"
                    :rules="surnameRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="genderId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="genderItems"
                    :rules="[(v) => !!v || 'Gender is required']"
                    label="Select Gender *"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="email"
                    label="Email *"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="phone"
                    label="Phone *"
                    :rules="phoneRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="dob"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dob"
                        label="Date of Birth *"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dob" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(dob)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="12" sm="4">
                  <v-select
                    v-model="stateOfResidenceId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="residentItems"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="State of Residence*"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="lgaOfResidenceId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="lgaOfResidenceItems"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="LGA of Residence *"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="residence_address"
                    :rules="addressRules"
                    label="Residential Address *"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12"> Government Issued ID </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="meansOfIdId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="meansOfIdItems"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="Means of Identification *"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="IDNumber"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="ID Number *"
                    hint="ID Number"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12"> Next of Kin (NOK) </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="nok_first_name"
                    :rules="firstNameRules"
                    label="NOK First name *"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="nok_surname"
                    :rules="surnameRules"
                    label="NOK Surname *"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="nok_email"
                    :rules="emailRules"
                    label="NOK Email *"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="nok_phone"
                    :rules="phoneRules"
                    label="NOK Phone *"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
        <v-btn text @click="backtoFirstStep">Back</v-btn>
        &nbsp;
        <v-btn
          color="primary rounded-0"
          :disabled="signupBtnStatus"
          @click="updateAccount"
        >{{ signupBtnText }}</v-btn>
      </v-stepper-content>

      <!-- <v-stepper-step step="3" complete>        <h4> Upload Documents </h4>
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card class="mb-12 white">
          <Uploads />

        </v-card>
        <v-btn color="primary rounded-0" @click="e13 = 3">Continue</v-btn>
        <v-btn text @click="e13 = 2" >Back to Step 2</v-btn>
      </v-stepper-content> -->
    </v-stepper>
    <v-snackbar v-model="snackbar">
      {{ snackbartext }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- </v-row> -->
  </div>
</template>

<style scoped>
h1 {
  font-size: 30px !important;
  font-weight: bolder;
}
.createAcct {
  height: 65px !important;
  padding: 15px;
  border-left: 5px solid #329332;
}
</style>

<script>
//import Uploads from '@/components/ui/landing/signup/Uploads.vue'
//import myServices from '../../../../assets/services/myservices'
import httpServ from '../services/HTTPService'
import tts from '../services/TTSService'

export default {
  components: {
    //Uploads
  },
  data() {
    return {
      // eslint-disable-next-line comma-dangle
      signupBtnStatus: false,
      signupBtnText: 'Submit',
      snackbar: false,
      snackbartext: '',
      e13: 1,
      date_of_incorporation: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      modal: false,
      valid: true,
      company_name: '',
      companyNameRules: [
        (v) => !!v || 'Company Name is required',
        (v) =>
          (v && v.length <= 20) ||
          'Company Name must be less than 20 characters'
      ],

      tinName: '',
      tinNameRules: [
        (v) => !!v || 'Tin Name is required',
        (v) => /^\d{8}-\d{4}$/.test(v) || 'TIN must be valid 12345678-0001',
        (v) => (v && v.length <= 13) || 'Tin must be 13 character long'
      ],

      business_phone: '',
      phoneRules: [
        (v) => !!v || 'Phone Number is required',
        (v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
        (v) => (v && v.length <= 11) || 'Phone Number must be 11 figures'
      ],

      business_email: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      CAC_registration_number: '',
      cacRules: [
        (v) => !!v || 'CAC Number is required',
        (v) => (v && v.length <= 11) || 'Name must be less than 11 characters'
      ],
      company_address: '',
      addressRules: [
        (v) => !!v || 'Commpany Address is required',
        (v) =>
          (v && v.length <= 35) ||
          'Commpany Address must be less than 35 characters'
      ],

      first_name: '',
      firstNameRules: [(v) => !!v || 'First Name is required'],
      middle_name: '',
      middleNameRules: [(v) => !!v || 'Middle Name is required'],
      surname: '',
      surnameRules: [(v) => !!v || 'Surname is required'],
      phone: '',
      email: '',
      dob: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      stateOfResidenceId: 0,
      lgaOfResidenceId: 0,
      lgaOfResidenceItems: [],
      residence_address: '',

      select: null,
      residenceStateId: 0,
      residentItems: [],
      alllga: [],

      mainBusinessLineId: 0,
      mainBusItems1: [],
      secondBusinessLineId: 0,
      secBusItems2: [],
      companyOwnershipId: 0,
      companyItems: [],
      genderId: 0,
      genderItems: [
        {
          id: 1,
          title: 'Male'
        },
        {
          id: 2,
          title: 'Female'
        }
      ],
      meansOfIdId: 0,
      IDNumber: '',
      meansOfIdItems: [
        {
          id: 1,
          title: 'Permanent Voter Card'
        },
        {
          id: 2,
          title: 'National Driver License'
        },
        {
          id: 3,
          title: 'International Passport'
        },
        {
          id: 4,
          title: 'National ID Card'
        }
      ],
      nok_first_name: '',
      nok_surname: '',
      nok_email: '',
      nok_phone: '',
      storedUserData: {}
    }
  },
  watch: {
    stateOfResidenceId: {
      handler(newvalue) {
        //////console.log('item changed', newvalue)
        //////console.log('state id is ', this.stateId)
        this.lgaOfResidenceItems = this.alllga.filter(
          (lga) => lga.state_id === this.stateOfResidenceId.id
        )
        //////console.log('all the lga is ', this.statelgas)
      },
      immediate: true
    },
    mainBusinessLineId: {
      handler(newvalue) {
        this.secBusItems2 = this.mainBusItems1.filter(
          (businessline2) => businessline2.id !== this.mainBusinessLineId.id
        )
      },
      immediate: true
    }
  },
  created() {
    //const resValue1 = myServices.getCreateAccountData()
    const formOptionsUrl = httpServ.getGuestUrl('/formOptions/createAccount')
    const resValue1 = httpServ.makeRequest(formOptionsUrl, 'GET')

    const newmainBusItems1 = {}
    let newsecBusItems2 = []

    resValue1.then((result) => {
      //console.log('our pree result is ', result)
      if (result.status === 200) {
        this.mainBusItems1 = result.data.main_line_of_business_rf
        newsecBusItems2 = result.data.main_line_of_business_rf
        //console.log('oya ', newsecBusItems2)
        this.companyItems = result.data.company_ownership_type_rf
        this.residentItems = result.data.residence_state_rf
        this.alllga = result.data.residence_lga_rf
      } else {
        //
      }
    })
    const ourUserProfile = JSON.parse(
      httpServ.getSessionItem('userUpdateData')
    )

    //console.log('test session data', ourUserProfile)
    if (ourUserProfile.data.membership_id.length > 8) {
      //console.log('this is our session data', ourUserProfile)
      this.userProfilemembership_id = ourUserProfile.data.membership_id
      const new_local = {
        data: {
          membership_id: ourUserProfile.data.membership_id,
          CAC_certificate_ext: '',
          ID_card_ext: '',
          passport_photo_ext: ''
        }
      }

      //console.log('this is our old local userProfile', this.userProfile)
      //console.log('this is our new local userProfile', new_local)
      const sendData = {
        membership_id: ourUserProfile.data.membership_id
      }
      //const resValue2 = httpServ.getUpdate(sendData)
      const authenUrl = httpServ.getAuthenUrl('/members/updateAccountData')
      const resValue2 = httpServ.makeRequest(authenUrl, 'POST', sendData)

      resValue2.then((result) => {
        if (result.status === 200) {
          console.log(result)
          this.business_email = result.data.company_data.business_email
          this.business_phone = result.data.company_data.business_phone
          this.company_name = result.data.company_data.company_name
          this.CAC_registration_number =
            result.data.company_data.CAC_registration_number
          this.date_of_incorporation =
            result.data.company_data.date_of_incorporation
          this.tinName = result.data.company_data.TIN
          this.companyOwnershipId = {
            id: result.data.company_data.company_ownership_type_rf
          }
          this.residenceStateId = {
            id: result.data.company_data.HQ_residence_state_rf
          }
          this.company_address = result.data.company_data.company_address

          this.gender_rf = {
            id: result.data.company_contact.gender_rf
          }
          this.mainBusinessLineId = {
            id: result.data.LOB.main_line_of_business_rf
          }
          //console.log('our state 1  is ', this.residentItems)
          setTimeout(() => {
            this.secBusItems2 = newsecBusItems2.filter(
              (businessline2) =>
                businessline2.id !== result.data.LOB.main_line_of_business_rf
            )
            this.lgaOfResidenceItems = this.alllga.filter(
              (lga) =>
                lga.state_id === result.data.company_contact.residence_state_rf
            )
          }, 4000)

          this.secondBusinessLineId = {
            id: result.data.LOB.second_line_of_business_rf
          }

          this.first_name = result.data.company_contact.first_name
          this.middle_name = result.data.company_contact.middle_name
          this.surname = result.data.company_contact.surname
          this.dob = result.data.company_contact.dob
          this.phone = result.data.company_contact.phone
          this.email = result.data.company_contact.email
          this.stateOfResidenceId = {
            id: result.data.company_contact.residence_state_rf
          }
          this.lgaOfResidenceId = {
            id: result.data.company_contact.residence_lga_rf
          }
          this.residence_address =
            result.data.company_contact.residence_address
          this.meansOfIdId = {
            id: result.data.company_contact.means_of_identification_rf
          }
          this.genderId = {
            id: result.data.company_contact.gender_rf
          }
          this.IDNumber = result.data.company_contact.IDNumber
          this.nok_first_name = result.data.company_contact.nok_first_name
          this.nok_surname = result.data.company_contact.nok_surname
          this.nok_email = result.data.company_contact.nok_email
          this.nok_phone = result.data.company_contact.nok_phone

          this.storedUserData = {
            membership_id: result.data.company_contact.membership_id,
            CAC_certificate_ext :result.data.picture_ext.CAC_certificate_ext !== undefined
              ? result.data.picture_ext.CAC_certificate_ext
              : 'none',
            ID_card_ext:result.data.picture_ext.ID_card_ext !== undefined
              ? result.data.picture_ext.ID_card_ext
              : 'none',
            passport_photo_ext:result.data.passport_photo_ext !== undefined
              ? result.data.passport_photo_ext
              : 'none'
          }
          //console.log('first',this.storedUserData )
          httpServ.setSessionJson('storedUserData', this.storedUserData)
        } else {
          //
        }
      })
    } else {
      this.$router.push({ name: 'signup' })
    }
  },
  methods: {
    backtoFirstStep: function () {
      this.e13 = 1
      window.scrollTo(0, 0)
    },
    firstStepProceed: function () {
      if (this.$refs.companydetailform.validate()) {
        this.e13 = 2
      }
    },
    updateAccount: function () {
      /*const ourUserProfile = JSON.parse(
        httpServ.getSessionItem('userUpdateData')
      )*/
      console.log(this.storedUserData)
      if (this.$refs.contactdetailform.validate()) {
        const sendData = {
          business_email: this.business_email,
          business_phone: this.business_phone,
          company_name: this.company_name,
          CAC_registration_number: this.CAC_registration_number,
          date_of_incorporation: this.date_of_incorporation,
          TIN: this.tinName,
          company_ownership_type_rf: this.companyOwnershipId.id,
          HQ_residence_state_rf: this.residenceStateId.id,
          company_address: this.company_address,
          first_name: this.first_name,
          middle_name: this.middle_name,
          surname: this.surname,
          gender_rf: this.genderId.id,
          dob: this.dob,
          phone: this.phone,
          email: this.email,
          residence_state_rf: this.residenceStateId.id,
          residence_lga_rf: this.lgaOfResidenceId.id,
          residence_address: this.residence_address,
          means_of_identification_rf: this.meansOfIdId.id,
          IDNumber: this.IDNumber,
          nok_first_name: this.nok_first_name,
          nok_surname: this.nok_surname,
          nok_email: this.nok_email,
          nok_phone: this.nok_phone,
          main_line_of_business_rf: this.mainBusinessLineId.id,
          second_line_of_business_rf: this.secondBusinessLineId.id,
          membership_id: this.storedUserData.membership_id
        }

        this.signupBtnStatus = true
        this.signupBtnText = 'Submitting...'
        const authenUrl = httpServ.getAuthenUrl('/members/updateAccount')
        const resValue3 = httpServ.makeRequest(authenUrl, 'POST', sendData)

        resValue3.then((result) => {
          console.log('our result is ', result)
          if (result.status === 200) {
            this.postCreateAccount(result.data)
          } else {
            this.signupBtnStatus = false
            this.signupBtnText = 'Submit'
            this.snackbar = true
            this.snackbartext = result.data.error
          }
          //////console.log('our response value is ', result)
        })
      } else {
        this.snackbar = true
        this.snackbartext = 'Make sure you provide all mandatory data'
      }
    },
    postCreateAccount: function (data) {
      this.signupBtnText = 'Submitted'
      this.snackbar = true
      this.snackbartext = 'Data submited! Please upload your documents'
      //call voice
      const speech =
        'You have completed your data entry activity. The remaining  step is to upload your documents'

      console.log(httpServ.getSessionJson('storedUserData'))
      //httpServ.setSessionItem('processStatus', 'update')
      //httpServ.setSessionItem('processStatus', 'update')
      tts.speak(speech)
      setTimeout(() => {
        this.signupBtnStatus = false
        this.$router.push({
          name: 'uploads',
          params: { membershipid: data.membership_id }
        })
      }, 5000)
    }
  }
}
</script>