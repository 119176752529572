<template>
  <div>
    <Toolbar />
    <AccVerification />
    <FooterPane />
  </div>
</template>

<script>
/*import Toolbar4 from '@/components/ui/landing/toolbar/Toolbar4.vue'
import AccVerification from '@/components/ui/accountVerification/AccVerification.vue'
import Footer4Entry from '@/components/ui/landing/footer/Footer4Entry.vue'*/

import Toolbar from '@/nfmp_components/domain_components/Toolbar.vue'
import AccVerification from '@/nfmp_components/AccVerification.vue'
import FooterPane from '@/nfmp_components/domain_components/Footer.vue'

export default {
  components: {
    Toolbar,
    AccVerification,
    FooterPane

  }
}
</script>