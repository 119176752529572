<template>
  <v-sheet color="">
    <Toolbar4 />
    <UpdateUploadDocument />
    <Footer4Entry />
  </v-sheet>
</template>

<script>
import UpdateUploadDocument from '@/components/ui/landing/signup/UpdateUploadDocument.vue'
import Toolbar4 from '@/components/ui/landing/toolbar/Toolbar4.vue'
import Footer4Entry from '@/components/ui/landing/footer/Footer4Entry.vue'

export default {
  components: {
    UpdateUploadDocument,
    Toolbar4,
    Footer4Entry
  }
}
</script>
