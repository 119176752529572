<template>
  <div class="bg-div">
    <v-sheet>
      <v-container class="py-4 py-lg-8">
        <div class="text-center">
          <!-- <div class="text-uppercase font-weight-bold body-2 primary--text mb-2">Work with us</div> -->
          <h2 class="text-h4">Platform Featuresss</h2>
        </div>
        <v-row class="mt-4">
          <v-col v-for="(item, i) in features" :key="i" cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="transparent" rounded class="pa-2">
                  <v-icon class="icon-color">{{ item.icon }}</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">{{ item.title }}</div>
                <div class="text-h6 mt-1 desc">{{ item.description }}</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>

export default {
  data() {
    return {
      features: [{
        icon: 'mdi-cast-connected',
        title: 'Automated Services',
        description: 'An online Single Window for registration, application for certificate/license, and certificate/license issuance.'
      }, {
        icon: 'mdi-human-greeting-proximity',
        title: 'Coordination Of Value Chain',
        description: 'Coordination with every stakeholder in the fertilizer industry value chain including laboratories, the National Port Authority, FIRS, CAC, NAFDAC etc. to bring harmony to the system'
      }, {
        icon: 'mdi-email-open-multiple-outline',
        title: 'Quicker Response',
        description: 'With stakeholders and regulators on a platform/Single Window, both the speed and ease of doing business is guaranteed'
      }, {
        icon: 'mdi-autorenew',
        title: 'Coordination Of Value Chain',
        description: 'Coordination with every stakeholder in the fertilizer industry value chain including laboratories, the National Port Authority, FIRS, CAC, NAFDAC etc. to bring harmony to the system'
      }]
    }
  }
}
</script>

<style scoped>
*{
  background-color: #E8F5E9 !important;
}
.icon-color{
    color: #4CAF50 !important;
    font-size: 38px !important;
}
.text-h5, .text-h4{
  color: #4CAF50 !important;
}
.desc{
  color: grey;
  font-size: 14px !important;
}
</style>
